import { APP_INITIALIZER, Injector, NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './layout/main/main.component';
import { PageLoaderComponent } from './shared/component/page-loader/page-loader.component';
import { TopLoaderComponent } from './shared/component/top-loader/top-loader.component';
import { SharedModule } from './shared/shared.module';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HelperService } from './shared/service/helper.service';
import { ToastrModule } from 'ngx-toastr';
import { WINDOW_PROVIDERS } from './window.providers';

import { Store, StoreModule } from '@ngrx/store';
import { MainModule } from './layout/main/main.module';
import { EffectsModule } from '@ngrx/effects';
import { logbookAppReducer, LogbookAppState } from './store/logbook.reducer';
import { UserEffects } from './store/user/user.effects';
import { AppEffects } from './store/app/effects';
import { MainEffects } from './store/main/main.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { Subscription } from 'rxjs';
import * as AppActions from './store/app/actions';
import { MaskComponent } from './shared/component/mask/mask.component';
import { PageHeaderService } from './shared/service/page-header/page-header.service';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ForbiddenComponent } from './view/error/forbidden/forbidden.component';
import { GlobalErrorHandlerService } from './shared/service/error-service/global-error-handler.service';
import { MonitoringService } from './shared/service/error-service/monitoring.service';
import { UserConfigurationEffects } from './store/user-configuration/user-configuration.effects';
import { MomentDatePipe } from './shared/pipe/moment-date.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormModule } from './view/settings/form/form.module';
import { LogoutComponent } from './view/logout/logout.component';
import { ClientSelectionComponent } from './view/client-selection/client-selection.component';
import { RolesEffects } from './store/settings/roles/roles.effects';
import { RightsEffects } from './store/settings/rights/rights.effects';
import { ClientEffects } from './store/settings/client/client.effect';
import { UsersEffects } from './store/settings/users/users.effects';
import { AdvancedFilterEffects } from './store/advanced-filter/advanced-filter.effects';
import { LogbooksEffects } from './store/logbooks/logbooks.effects';
import { LogbookScopesEffects } from './store/scopes/logbook-scopes/logbook-scopes.effects';
import { FormScopesEffects } from './store/scopes/form-scopes/form-scopes.effects';
import { FormsEffects } from './store/forms/forms.effects';
import { WorkflowsEffects } from './store/settings/workflows/workflows.effects';
import { ReasonEffects } from './store/reason/reason.effects';
import { LogbookMasterDataEffects } from './store/settings/logbook-master-data/logbook-master-data.effects';
import { FormMasterDataEffects } from './store/settings/form-master-data/form-master-data.effects';
import { LogbookVersionsEffects } from './store/logbook-versions/logbook-versions.effects';
import { HomeEffects } from './store/home/home.effects';
import { FilterFormEffects } from './store/filter/form/form.effects';
import { FilterLogbookEffects } from './store/filter/logbook/logbook.effects';
import { FilterUserEffects } from './store/filter/user/user.effects';
import { LogsFormEntriesEffects } from './store/my-tasks/logs-form-entries/logs-form-entries.effects';
import { LogbookTasksEffects } from './store/my-tasks/logbook-tasks/logbook-tasks.effects';
import { FormTasksEffects } from './store/my-tasks/form-tasks/form-tasks.effects';
import { MasterDataTasksEffects } from './store/my-tasks/master-data-tasks/master-data-tasks.effects';
import { FormEntriesEffects } from './store/reports/form-entries/form-entries.effects';
import { MyReportsEffects } from './store/reports/my-reports/my-reports.effects';
import { NavItemsComponent } from './layout/nav-items/nav-items.component';
import { AuditTrailEffects } from './store/audit-trail/audit-trail.effects';
import { FormTemplatesEffects } from './store/reports/form-templates/form-templates.effects';
import { LogbookTemplatesEffects } from './store/reports/logbook-templates/logbook-templates.effects';
import { LogsReportsEffects } from './store/reports/logs-reports/logs-reports.effects';
import { ForgotPinEffects } from './store/forgot-pin/forgot-pin.effects';
import { ResetPinComponent } from './view/reset-pin/reset-pin.component';
import { InvalidLinkComponent } from './view/error/invalid-link/invalid-link.component';
import { CheckForUpdateService } from './check-for-update.service';
import { CheckInEffects } from './store/check-in/check-in.effects';
import { MomentModule } from 'angular2-moment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TemplatesHelperService } from './shared/service/reports/templates/templates-helper.service';
import { PageConfigurationEffects } from './store/page-configuration/page-configuration.effects';
import { DocumentGeneratorService } from './shared/component/document-generator/document-generator.service';
import { SavableFilterEffects } from './store/filter/savable-filter/savable-filter.effects';
import { ActivityTypeEffects } from './store/settings/activity-type/activity-type.effects';
import { StatesEffects } from './store/settings/states/states.effects';
const { version: appVersion } = require('../../package.json');
import { RuleEffects } from './store/settings/state-rule-and-automation/rules/rule.effects';
import { AutomationEffects } from './store/settings/state-rule-and-automation/automations/automation.effects';
import { FilterRuleTypeEffects } from './store/filter/rule-type/rule-type.effects';
import { FilterAutomationTypeEffects } from './store/filter/automation-type/automation-type.effects';
import { StateRuleAndAutomationEffects } from './store/settings/state-rule-and-automation/state-rule-and-automation.effects';
import { CustomTranslateLoader } from './custom-translate-loader';

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return `[MISSING]${params.key}`;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PageLoaderComponent,
    TopLoaderComponent,
    MaskComponent,
    ForbiddenComponent,
    LogoutComponent,
    ClientSelectionComponent,
    NavItemsComponent,
    ResetPinComponent,
    InvalidLinkComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormModule,
    ToastrModule.forRoot({
      maxOpened: 8,
      preventDuplicates: true,
      countDuplicates: false,
    }),
    EffectsModule.forRoot([
      UserEffects,
      UserConfigurationEffects,
      AppEffects,
      MainEffects,
      RolesEffects,
      RightsEffects,
      ClientEffects,
      UsersEffects,
      AdvancedFilterEffects,
      LogbooksEffects,
      LogbookVersionsEffects,
      LogbookScopesEffects,
      FormsEffects,
      FormScopesEffects,
      ReasonEffects,
      WorkflowsEffects,
      LogbookMasterDataEffects,
      FormMasterDataEffects,
      HomeEffects,
      FilterFormEffects,
      FilterLogbookEffects,
      FilterUserEffects,
      LogsFormEntriesEffects,
      LogbookTasksEffects,
      FormTasksEffects,
      MasterDataTasksEffects,
      FormEntriesEffects,
      MyReportsEffects,
      AuditTrailEffects,
      FormTemplatesEffects,
      LogbookTemplatesEffects,
      LogsReportsEffects,
      ForgotPinEffects,
      CheckInEffects,
      PageConfigurationEffects,
      SavableFilterEffects,
      ActivityTypeEffects,
      StatesEffects,
      RuleEffects,
      AutomationEffects,
      FilterRuleTypeEffects,
      FilterAutomationTypeEffects,
      StateRuleAndAutomationEffects,
    ]),
    StoreModule.forRoot(logbookAppReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 10,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    MainModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [],
  providers: [
    HelperService,
    TranslateService,
    CheckForUpdateService,
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Store],
      multi: true,
    },
    PageHeaderService,
    CookieService,
    MonitoringService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    DatePipe,
    MomentDatePipe,
    TemplatesHelperService,
    DocumentGeneratorService,
  ],
  bootstrap: [AppComponent],
  exports: [PageLoaderComponent, HttpClientModule],
})
export class AppModule {}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  store: Store<LogbookAppState>,
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any, reject: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve());
      let userStore: Subscription;
      let translateSubscription: Subscription;
      translate.setDefaultLang('en');
      store.dispatch(new AppActions.GetCurrentUser('application-parameters'));

      locationInitialized.then(() => {
        userStore = store.select('user').subscribe((state) => {
          if (state.language !== null) {
            userStore?.unsubscribe();

            const langToSet = state.language;
            translateSubscription = translate.use(langToSet).subscribe({
              next: (translateResponseData) => {
                if (translateResponseData !== undefined) {
                  resolve();
                }
              },
              error: () => {
                reject();
              },
              complete: () => {
                translateSubscription?.unsubscribe();
              },
            });
          }

          if (state.language !== '' && state.language) {
            moment.updateLocale(state.language, {
              week: {
                dow: 1,
                doy: 7,
              },
            });
          }
        });
      });
    });
}
