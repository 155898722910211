import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AutomationTypeFilterActions from './automation-type.actions';
import { map, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutomationService } from '../../settings/state-rule-and-automation/automations/automation.service';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IAutomationType } from '../../../shared/component/filter/filter.class';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FilterAutomationTypeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: AutomationService,
    private readonly translate: TranslateService,
  ) {}

  getAutomationTypeFilterData = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationTypeFilterActions.ActionTypes.GET_AUTOMATION_TYPE_FILTER_DATA),
      switchMap(() => {
        return this.service.getAutomationTypes().pipe(
          map((response: IGetManyResponse<IAutomationType>) =>
            AutomationTypeFilterActions.getAutomationTypeFilterDataCompleted({
              payload: response.data.map((row: IAutomationType) => {
                return {
                  ...row,
                  displayName: this.translate.instant(`filterCard.automationType.${row.name}`),
                };
              }),
            }),
          ),
          catchError((error) => of(AutomationTypeFilterActions.getAutomationTypeFilterDataCompleted({ payload: [] }))),
        );
      }),
      catchError((error) => of(AutomationTypeFilterActions.getAutomationTypeFilterDataCompleted({ payload: [] }))),
    ),
  );
}
