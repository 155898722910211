import { createAction, props } from '@ngrx/store';
import { IAutomationType } from '../../../shared/component/filter/filter.class';

export enum ActionTypes {
  GET_AUTOMATION_TYPE_FILTER_DATA = '[AUTOMATION TYPE FILTER] GET AUTOMATION TYPE FILTER DATA',
  GET_AUTOMATION_TYPE_FILTER_DATA_COMPLETED = '[AUTOMATION TYPE FILTER] GET AUTOMATION TYPE FILTER DATA COMPLETED',
  RESET_AUTOMATION_TYPE_FILTER = '[AUTOMATION TYPE FILTER] RESET AUTOMATION TYPE FILTER',
}

export const getAutomationTypeFilterData = createAction(ActionTypes.GET_AUTOMATION_TYPE_FILTER_DATA);

export const getAutomationTypeFilterDataCompleted = createAction(
  ActionTypes.GET_AUTOMATION_TYPE_FILTER_DATA_COMPLETED,
  props<{ payload: IAutomationType[] }>(),
);

export const resetAutomationTypeFilter = createAction(ActionTypes.RESET_AUTOMATION_TYPE_FILTER);
