import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as AutomationTypeFilterActions from './automation-type.actions';
import { IAutomationType } from '../../../shared/component/filter/filter.class';

export interface IAutomationTypeFilterState {
  isLoading: boolean;
  isLoaded: boolean;
  data: IAutomationType[];
}

export const initialState: IAutomationTypeFilterState = {
  isLoading: false,
  isLoaded: false,
  data: [],
};

export const automationTypeFilterReducer: ActionReducer<IAutomationTypeFilterState> = createReducer(
  initialState,
  on(AutomationTypeFilterActions.getAutomationTypeFilterData, (state: IAutomationTypeFilterState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(
    AutomationTypeFilterActions.getAutomationTypeFilterDataCompleted,
    (state: IAutomationTypeFilterState, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      data: payload,
    }),
  ),
  on(AutomationTypeFilterActions.resetAutomationTypeFilter, (state: IAutomationTypeFilterState) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    data: [],
  })),
);
