import { createAction, props } from '@ngrx/store';
import { IRelevantData } from './state-rule-and-automation.model';

export enum ActionTypes {
  GET_RELEVANT_DATA = '[STATE RULES AND AUTOMATIONS] GET RELEVANT DATA',
  GET_RELEVANT_DATA_COMPLETED = '[STATE RULES AND AUTOMATIONS] GET RELEVANT DATA COMPLETED',
  FETCH_ERROR = '[STATE RULES AND AUTOMATIONS] FETCH ERROR',
}

export const GetRelevantData = createAction(ActionTypes.GET_RELEVANT_DATA);

export const GetRelevantDataCompleted = createAction(
  ActionTypes.GET_RELEVANT_DATA_COMPLETED,
  props<{ payload: IRelevantData }>(),
);

export const FetchError = createAction(ActionTypes.FETCH_ERROR, props<{ error: object }>());
