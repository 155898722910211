import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IAddAutomation, IAutomation, IBulkEditAutomation } from './automation.model';
import { IAutomationType } from '../../../../shared/component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  private readonly routes = {
    AUTOMATIONS: '/automations/',
    AUTOMATION_TYPES: '/automation-types/',
    BULK: 'bulk/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getAutomations(params: Record<string, string | number>): Observable<IGetManyResponse<IAutomation>> {
    return this.http.post<IGetManyResponse<IAutomation>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addAutomation(automation: IAddAutomation, issuer: IIssuer | null): Observable<IBaseOneResponse<IAutomation>> {
    return this.http.post<IBaseOneResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}`, {
      issuer,
      payload: automation,
    });
  }

  public editAutomation(
    automation: IAddAutomation,
    id: number,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IAutomation>> {
    return this.http.put<IBaseOneResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}${id}/`, {
      issuer,
      payload: automation,
    });
  }

  public deleteAutomation(
    automationIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IAutomation>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IAutomation>>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: automationIds,
          },
        },
      },
    );
  }

  public bulkDeleteAutomation(
    automationIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IAutomation>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IAutomation>>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: automationIds,
          },
        },
      },
    );
  }

  public bulkEditAutomation(
    automations: IBulkEditAutomation[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IAutomation>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IAutomation>>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}${this.routes.BULK}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: automations,
      },
    );
  }

  public getAutomationTypes(): Observable<IGetManyResponse<IAutomationType>> {
    return this.http.get<IGetManyResponse<IAutomationType>>(`${this.baseUrl}${this.routes.AUTOMATION_TYPES}`);
  }
}
