import { IFormField } from '../../../../shared/model/interface/scw-form.model';
import { ScwMatInputRule } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ISelect, ScwMatSelectRule } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IState } from '../../../../store/settings/states/states.model';
import { IActivityType } from '../../../../store/settings/activity-type/activity-type.model';
import { ITableQueryWithIsActive } from '../../../../shared/model/interface/common-page.model';

export declare type ModalType = 'add' | 'edit' | 'bulk-edit' | 'delete' | 'bulk-delete';

export interface IRuleForm {
  isAllLogbooks: IFormField<boolean, []>;
  name: IFormField<string | null, ScwMatInputRule>;
  isActive: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  logbooks: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  states: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  nextStates: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  activityTypes: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  ruleType: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
}

export interface IRuleFormRules {
  name: ScwMatInputRule[];
  isActive: ScwMatSelectRule[];
  logbooks: ScwMatSelectRule[];
  ruleType: ScwMatSelectRule[];
  states: ScwMatSelectRule[];
  nextStates: ScwMatSelectRule[];
  activityTypes: ScwMatSelectRule[];
}

export interface IRuleTableQuery extends ITableQueryWithIsActive {
  logbookIds: number[] | null;
  ruleTypes: number[] | null;
}

export interface IChangeContent {
  type: 'state' | 'nextState' | 'activityType' | 'approvalStepTrigger' | 'useMasterData';
  content: (IState | IActivityType | ISelect<number, string>)[] | boolean | number;
}

export enum ERuleErrorTypes {
  COLLISION = 'automations_collision_exists_cannot_save',
}
