import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as StateRuleAndAutomationActions from './state-rule-and-automation.actions';
import { map, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IRelevantData } from './state-rule-and-automation.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../logbook.reducer';
import { StateRuleAndAutomationService } from './state-rule-and-automation.service';

@Injectable()
export class StateRuleAndAutomationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: StateRuleAndAutomationService,
  ) {}

  getRelevantData = createEffect(() =>
    this.actions$.pipe(
      ofType(StateRuleAndAutomationActions.ActionTypes.GET_RELEVANT_DATA),
      switchMap(() => {
        return this.service.getRelevantData().pipe(
          map((response: IRelevantData) =>
            StateRuleAndAutomationActions.GetRelevantDataCompleted({ payload: response }),
          ),
          catchError((error) => of(StateRuleAndAutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(StateRuleAndAutomationActions.FetchError({ error }))),
    ),
  );
}
