import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivityTypeService } from './activity-type.service';
import * as ActivityTypeActions from './activity-type.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IActivityType,
  IAddActivityType,
  IBulkEditActivityType,
  IValidatePayload,
  IValidationResult,
} from './activity-type.model';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { LogbookAppState } from '../../logbook.reducer';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IActivityTypeTableQuery, ModalType } from '../../../view/settings/activity-type/activity-type.model';

@Injectable()
export class ActivityTypeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: ActivityTypeService,
  ) {}

  getActivityTypeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.ACTIVITY_TYPE_DATA_LOADING),
      switchMap((payload: { tableQuery: IActivityTypeTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        return this.service.getActivityTypes(httpParams).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IActivityType>) =>
            ActivityTypeActions.ActivityTypesDataLoaded({ payload: response }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );

  addActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.ADD_ACTIVITY_TYPE),
      switchMap((payload: { activityType: IAddActivityType; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addActivityType(payload.activityType, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IActivityType>) =>
            ActivityTypeActions.AddActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );

  editActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.EDIT_ACTIVITY_TYPE),
      switchMap((payload: { activityType: IAddActivityType; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editActivityType(payload.activityType, payload.id, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IActivityType>) =>
            ActivityTypeActions.EditActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );

  deleteActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.DELETE_ACTIVITY_TYPE),
      switchMap((payload: { id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteActivityType(payload.id, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IActivityType>) =>
            ActivityTypeActions.DeleteActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );

  bulkDeleteActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.BULK_DELETE_ACTIVITY_TYPE),
      switchMap((payload: { activityTypeIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkDeleteActivityType(payload.activityTypeIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IActivityType>>) =>
            ActivityTypeActions.BulkDeleteActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            console.error(error);
            return of(ActivityTypeActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => {
        console.error(error);
        return of(ActivityTypeActions.FetchError({ payload: error }));
      }),
    ),
  );

  bulkEditActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.BULK_EDIT_ACTIVITY_TYPE),
      switchMap((payload: { activityTypes: IBulkEditActivityType[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditActivityType(payload.activityTypes, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IActivityType>>) =>
            ActivityTypeActions.BulkEditActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );

  validateActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ActionTypes.VALIDATE_ACTIVITY_TYPE),
      switchMap((payload: { activityTypeIds: number[]; operation: ModalType; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const formattedPayload: IValidatePayload = {
          activityTypes: payload.activityTypeIds,
        };

        return this.service.validateUtilization(formattedPayload, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IValidationResult>) =>
            ActivityTypeActions.validateActivityTypeCompleted({ payload: response.data }),
          ),
          catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(ActivityTypeActions.FetchError({ payload: error }))),
    ),
  );
}
