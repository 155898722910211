import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './check-in.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import { CheckInService } from './check-in.service';
import { ICheckInResponse } from './check-in.model';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { HomeCheckInUserInterface } from '../home/home.model';

@Injectable()
export class CheckInEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly checkInService: CheckInService,
  ) {}

  loadCheckInUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECKIN_USERS_LOADING),
      switchMap((payload: ObjectActions.CheckInUsersLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.checkInService.loadCheckInUsers(payload.formSubmissionId).pipe(
          switchMap((response: IGetManyResponse<HomeCheckInUserInterface>) => {
            return of(new ObjectActions.CheckInUsersLoaded(response) , new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addCheckInUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_CHECKIN_USERS),
      switchMap((payload: ObjectActions.AddCheckInUsers) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.checkInService.addCheckInUsers(payload.checkInUsers).pipe(
          switchMap((response: IBaseOneResponse<ICheckInResponse>) => {
            return of(new ObjectActions.AddCheckInUsersCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  updateCheckInUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_CHECKIN_USERS),
      switchMap((payload: ObjectActions.UpdateCheckInUsers) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.checkInService.updateCheckInUsers(payload.checkInUsers).pipe(
          switchMap((response: IBaseOneResponse<ICheckInResponse>) => {
            return of(new ObjectActions.UpdateCheckInUsersCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
