import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { IDefaultSelectionValues, IFilterCardOption } from '../../../shared/component/filter/filter.class';
import { ButtonGroupComponent } from '../../../shared/component/filter/button-group/button-group.component';
import { DropdownComponent } from '../../../shared/component/filter/dropdown/dropdown.component';
import { FormVersionStatus } from '../../../shared/component/filter/filterable-objects.class';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, zip } from 'rxjs';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import {
  EFormActivityType,
  IFormActionPayload,
  IFormExcel,
  IFormExcelData,
  IFormSettingsFilterOutput,
  IFormTableQuery,
  IFormVersion,
  IFormVersionHistory,
  IFormVersionSettings,
  IGetFormVersionsParams,
  IGetFormVersionHistoryParams,
  IPassedIssueDateForms,
} from '../../../store/forms/forms.model';
import { IDatatableOutputParameter } from '../../../shared/component/datatable/datatable.model';
import * as FormsActions from '../../../store/forms/forms.actions';
import * as PageConfigurationActions from '../../../store/page-configuration/page-configuration.actions';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { HelperService } from '../../../shared/service/helper.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ofType } from '@ngrx/effects';
import { IGenericObject } from '../../../shared/model/interface/generic.model';
import { TColorInput } from '../../../shared/component/scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import * as _ from 'lodash';
import { EActionType, EApprovalStatuses, EFormActivationFlow } from '../../../shared/model/enum/constants';
import * as moment from 'moment';
import { User } from '../../../store/user/model';
import {
  EFormActionsErrorType,
  FormActionsType,
  IFormCreate,
  IFormForm,
  IFormIoComponentKeyId,
  IPreviousFormVersion,
} from './form.model';
import { ISelect } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { IWorkflow } from '../../../store/settings/workflows/workflows.model';
import { defaultFormValue } from '../../../shared/model/interface/scw-form.model';
import { IWorkflowStepsData } from '../workflows/workflows.model';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import {
  ComponentUtilities,
  filterCardClassForTabPages,
  formIoConfiguration,
  TFormIoComponentKey,
} from '../../../shared/helper/component-utilities';
import { IIssuerAndReason } from 'src/app/shared/component/before-action-preparer/before-action-preparer.model';
import * as MainActions from '../../../store/main/main.actions';
import { EManualLogActionType, EManualLogObjectContentModel, ERights } from '../../../store/main/main.model';
import {
  EPdfInitialElementId,
  FileTypes,
  IBanner,
  IFile,
  IFormPdfProperties,
} from '../../../shared/component/file-generator/file-generator.model';
import { FormioForm } from 'angular-formio';
import { IIssuer } from 'src/app/shared/component/issuer/issuer.model';
import { IFormSubmission } from '../../../store/home/home.model';
import { ILogbookMasterDataFields } from '../../../store/settings/logbook-master-data/logbook-master-data.model';
import { IFormMasterDataDetail } from '../../../store/settings/form-master-data/form-master-data.model';
import { IModalContent } from '../logbook/logbook.model';
import * as AppActions from '../../../store/app/actions';
import { DEFAULT_EXCEL_UPLOAD_LIMIT_FOR_LOGBOOK_AND_FORM, NAME_COLUMN_MAX_LENGTH } from '../../../../constants';
import { ScwMatDropdownMenuItem } from '../../../shared/component/scw-mat-ui/scw-mat-dropdown/scw-mat-dropdown.model';
import { BeforeActionPreparerComponent } from '../../../shared/component/before-action-preparer/before-action-preparer.component';
import { FormsService } from '../../../store/forms/forms.service';
import { ToastrService } from 'ngx-toastr';
import { excelDateFormat, excelTimeFormat, IExcelDateFormat } from '../../../shared/model/enum/excel-date-format';
import {
  ICompareField,
  ICompareFormio,
  ICompareHeader,
} from '../../../shared/standalone/compare-changes/compare-changes.model';
import { IUserShortInfo } from '../../../store/settings/users/users.model';
import * as UsersActions from '../../../store/settings/users/users.actions';
import { ScopeSettingsNavigatorModalComponent } from '../../../shared/component/scope-settings-navigator-modal/scope-settings-navigator-modal.component';
import { IDocument, IDocumentBanner } from '../../../shared/component/document-generator/document-generator.model';
import { DocumentGeneratorService } from '../../../shared/component/document-generator/document-generator.service';
import { IClientSettingsState } from '../../../store/settings/client/client.model';
import { IConfigurationMeta } from '../../../store/page-configuration/page-configuration.model';
import { BulkErrorModalComponent } from '../../../shared/component/bulk-error-modal/bulk-error-modal.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TBulkActions } from '../../../shared/service/bulk-action/bulk-action.model';
import { BulkActionService } from '../../../shared/service/bulk-action/bulk-action.service';
import { BulkActionConfirmationModalComponent } from '../../../shared/component/bulk-action-confirmation-modal/bulk-action-confirmation-modal.component';
import * as ActivityTypeActions from '../../../store/settings/activity-type/activity-type.actions';
import { fetchAllDataDefaultTableQuery } from '../activity-type/activity-type.model';
import { IActivityType } from '../../../store/settings/activity-type/activity-type.model';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';

@OnDestroyDecorator
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, AfterViewInit {
  @ViewChild('draft_exist_modal') draftExistsModal!: TemplateRef<any>;
  @ViewChild('master_data_mismatch_modal') masterDataMismatchModal!: TemplateRef<any>;
  @ViewChild('submit_confirmation') submitModal!: TemplateRef<any>;
  @ViewChild('form_modal') formModal!: TemplateRef<any>;
  @ViewChild('ongoing_information_modal') ongoingInformationModal!: TemplateRef<any>;
  @ViewChild('passed_issue_date_modal', { static: true }) passedIssueDateModal!: TemplateRef<any>;
  @ViewChild('uploader') uploaderElementRef!: ElementRef;
  @ViewChild('excel_error_modal') excelErrorModalTemplateRef!: ViewChildDecorator;
  @ViewChild('beforeActionPreparerComponent') beforeActionPreparerComponent!: BeforeActionPreparerComponent;
  @ViewChild(ScopeSettingsNavigatorModalComponent) scopeModal!: ScopeSettingsNavigatorModalComponent;
  @ViewChild('bulk_error_modal', { static: false })
  bulkErrorModalComponent!: BulkErrorModalComponent;
  @ViewChild('bulk_action_confirmation_modal', { static: false })
  bulkActionConfirmationModalComponent!: BulkActionConfirmationModalComponent;
  public recordHeader: string = '';
  public isPdfEnhancement: boolean = true;

  constructor(
    private readonly translate: TranslateService,
    private readonly modal: NgbModal,
    private readonly storeActions: ActionsSubject,
    private readonly checkBoxElementRef: ElementRef,
    public readonly store: Store<logbookAppReducer.LogbookAppState>,
    public readonly helperService: HelperService,
    public readonly formService: FormsService,
    public readonly toast: ToastrService,
    @Inject('API_BASE_URL') public readonly baseUrl: string,
    private readonly documentGeneratorService: DocumentGeneratorService,
    public readonly bulkActionService: BulkActionService,
  ) {}

  protected readonly HelperService: typeof HelperService = HelperService;
  private subscriptions: Subscription[] = [];
  private readonly scwModalSm: string = 'scw-modal-sm overflow-auto';
  private readonly scwModalMd: string = 'scw-modal-md overflow-auto';
  private readonly scwModalXl: string = 'scw-modal-xl overflow-auto';
  private readonly scwModalXlWithDragSelector: string = 'scw-modal-xl overflow-auto drag-query-selector-class';
  private modalRef!: NgbModalRef;
  private draftVersionExistsModalRef!: NgbModalRef;
  private formVersionModalRef!: NgbModalRef;
  private allVersionsModalRef!: NgbModalRef;
  private historyModalRef!: NgbModalRef;
  private defaultModalRef!: NgbModalRef;
  private formPreviewRef!: NgbModalRef;
  private masterDataMismatchModalRef!: NgbModalRef;
  private passedIssueDateModalRef!: NgbModalRef;
  private scopeModalRef!: NgbModalRef;
  private selectedStatusFilters: number[] = [];
  private userTimeFormat!: string | null;
  private isMasterDataMisMatched: boolean = false;
  private allVersionOn: boolean = false;
  private getVersionHistoryParams: IGetFormVersionHistoryParams = {};
  private isFormIoKeysUnique!: boolean;
  private isSelectableComponentValueUnique!: boolean;
  private isAskForPinOnAction: boolean | undefined;
  private loggedInUserUsername: string | null = null;
  private selectedFormVersionRecordIds: number[] = [];
  public getFormVersionsParams: IGetFormVersionsParams = {
    formId: null,
    page: 1,
    rowsPerPage: 10,
  };
  public formVersionsCount$: number = 0;
  public isAllViewHistory: boolean = false;
  public readonly EApprovalStatuses = EApprovalStatuses;
  public readonly dueDateType: ISelect<number, string>[] = this.helperService.getTimeType();
  public readonly approvalStatuses: IGenericObject<string> = this.helperService.translateObjectValues(
    HelperService.numberEnumToObject(EApprovalStatuses, 'general.approvalStatuses'),
  );
  public readonly approvalStatusesClasses: IGenericObject<TColorInput> = Object.entries(EApprovalStatuses).reduce(
    (result: IGenericObject<TColorInput>, [key, value]) => {
      if (isNaN(Number(key))) {
        result[value] = _.camelCase(key) as TColorInput;
      }

      return result;
    },
    {},
  );
  public readonly completedAndApproved: EApprovalStatuses[] = [EApprovalStatuses.APPROVED, EApprovalStatuses.COMPLETED];
  public readonly actionTypesWithoutReason: FormActionsType[] = [
    'add-modal-open',
    'edit-modal-open',
    'add-new-version-modal-open',
    'activate',
    'submit',
    'bulk-submit',
    'bulk-activate',
  ];
  public formActivityTypeItems$: ISelect<number, string>[] = [];
  public formActivityTypeDropdownItems$: ISelect<number, string>[] = [];
  public yesNo: ISelect<number, string>[] = this.helperService.getYesNoDropdown();
  public workflowStepData$: IWorkflowStepsData[] = [];
  public formVersionTableHeaders: ITableHeader[] = [];
  public formVersionData$: IFormVersion[] = [];
  public formHistoryData$: IFormVersionHistory[] = [];
  public formTableData$: IFormVersionSettings[] = [];
  public workflowData$: IWorkflow[] = [];
  public userDefinedFieldInputModels: any[] = [];
  public modalContent: any[] = [];
  public previewModalContent: any[] = [];
  public previousVersionMasterData: IModalContent[] = [];
  public passedIssueDateForms!: IPassedIssueDateForms[];
  public workFlowStepsModalRef!: NgbModalRef;
  public defaultSelectionValues: IDefaultSelectionValues = {};
  public defaultFilterSelectionsSubject: Subject<IDefaultSelectionValues> = new Subject();
  public actionSubject: Subject<boolean> = new Subject<boolean>();
  public beforeActionPreparerActionSubject: Subject<boolean> = new Subject<boolean>();
  public formTablePageCount$!: number;
  public activeMasterDataId$!: number;
  public excelTemplateMasterDataId!: number | null;
  public activeMasterDataData$!: IFormMasterDataDetail;
  public formApprovalDisableEyeIcon: boolean = true;
  public entryReviewWorkFlowDisableEyeIcon: boolean = true;
  public isPredefinedFieldsValid!: boolean;
  public disableAllFields: boolean = false;
  public filtersReady: boolean = false;
  public minDate!: moment.Moment;
  public selectedForm!: IFormVersionSettings | null;
  public targetItem!: IFormVersion;
  public previousVersion!: IFormVersion | undefined;
  public isDraftExists: boolean = false;
  public isSelectedFormOngoing: boolean = false;
  public selectedFormOngoingForms: IFormSubmission[] = [];
  public selectedFormActivationInProgress: boolean = false;
  public formActionType!: FormActionsType;
  public issuerAndReason!: IIssuerAndReason;
  public isMasterMismatch: boolean = false;
  public isHasNotWorkflow: boolean = false;
  public searchBoxText!: string | null;
  public form: FormioForm = {
    components: [],
  };
  public formClone: FormioForm = {
    components: [],
  };
  public formCloneInitial: FormioForm = {
    components: [],
  };
  public formIoConfiguration: any = formIoConfiguration;
  public filterOptions: IFilterCardOption = {
    rows: [
      [
        {
          type: ButtonGroupComponent,
          cls: 'col-sm-5 m-t-4 m-b-5 buttonGroupFlex',
          elementId: 'versionType',
          outputOptions: {
            filterObjectId: 'versionType',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            buttons: [
              {
                text: this.translate.instant('general.all'),
                value: null,
              },
              {
                text: this.translate.instant('general.nonArchived'),
                value: 0,
              },
              {
                text: this.translate.instant('general.archived'),
                value: 1,
              },
            ],
            value: 0,
          },
        },
        {
          type: DropdownComponent,
          cls: filterCardClassForTabPages,
          object: FormVersionStatus,
          elementId: 'statusDropdown',
          outputOptions: {
            filterObjectId: 'selectedStatusId',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            badgeShowLimit: 1,
            text: this.translate.instant('filterCard.status.dropdownPlaceHolder'),
            isRequired: false,
          },
        },
      ],
    ],
  };
  public tableQuery: IFormTableQuery = {
    page: 1,
    rowsPerPage: 10,
    search: '',
    sort: {
      column: 'form',
      type: '-',
    },
  };
  public tableConfiguration: IConfigurationMeta[] = [];
  public formTableHeaders: ITableHeader[] = [];
  public selectedFormTableHeaders: ITableHeader[] = [];
  public workFlowStepHeaders: ITableHeader[] = [
    {
      value: 'stepName',
      name: this.translate.instant('general.datatable.headers.stepName'),
      selected: true,
      sortable: false,
    },
    {
      value: 'roleFormatted',
      name: this.translate.instant('general.datatable.headers.role'),
      selected: true,
      sortable: false,
    },
    {
      value: 'expirationDate',
      name: this.translate.instant('general.datatable.headers.expireTime'),
      selected: true,
      sortable: false,
    },
  ];
  public singleHistoryTableHeader: ITableHeader[] = [
    {
      value: 'action',
      name: this.translate.instant('settings.forms.modal.formHistory.action'),
      sortable: false,
    },
    {
      value: 'actionBy',
      name: this.translate.instant('settings.forms.modal.formHistory.actionBy'),
      sortable: false,
    },
    {
      value: 'createdAt',
      name: this.translate.instant('settings.forms.modal.formHistory.createdAt'),
      sortable: false,
    },
    {
      value: 'reason',
      name: this.translate.instant('settings.forms.modal.formHistory.reason'),
      sortable: false,
    },
  ];
  public allHistoryTableHeader: ITableHeader[] = [
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.version'),
      sortable: false,
    },
    {
      value: 'action',
      name: this.translate.instant('settings.forms.modal.formHistory.action'),
      sortable: false,
    },
    {
      value: 'actionBy',
      name: this.translate.instant('settings.forms.modal.formHistory.actionBy'),
      sortable: false,
    },
    {
      value: 'createdAt',
      name: this.translate.instant('settings.forms.modal.formHistory.createdAt'),
      sortable: false,
    },
    {
      value: 'reason',
      name: this.translate.instant('settings.logbooks.logbookHistory.reason'),
      sortable: false,
    },
  ];
  public historyTableHeader: ITableHeader[] = this.singleHistoryTableHeader;
  public issuerActions: string[] = [
    FormsActions.ADD_FORM_COMPLETED,
    FormsActions.EDIT_FORM_COMPLETED,
    FormsActions.CLONE_FORM_COMPLETED,
    FormsActions.DELETE_FORM_LOADED,
    FormsActions.SUBMIT_FORM_LOADED,
    FormsActions.ACTIVATE_FORM_LOADED,
    FormsActions.ARCHIVE_FORM_LOADED,
    FormsActions.FLAG_TO_BE_ARCHIVE_FORM_LOADED,
    FormsActions.ADD_FORM_VERSION_COMPLETED,
    FormsActions.FETCH_ERROR,
    MainActions.CHECK_PERMISSION_COMPLETED,
    MainActions.FETCH_ERROR,
    FormsActions.UPLOAD_EXCEL_COMPLETED,
    FormsActions.DOWNLOAD_ERROR_EXCEL_COMPLETED,
    FormsActions.BULK_SUBMIT_FORMS_COMPLETED,
    FormsActions.BULK_ACTIVATE_FORMS_COMPLETED,
  ];
  public reasonActions: string[] = [
    FormsActions.EDIT_FORM_COMPLETED,
    FormsActions.CLONE_FORM_COMPLETED,
    FormsActions.DELETE_FORM_LOADED,
    FormsActions.SUBMIT_FORM_LOADED,
    FormsActions.ACTIVATE_FORM_LOADED,
    FormsActions.ARCHIVE_FORM_LOADED,
    FormsActions.FLAG_TO_BE_ARCHIVE_FORM_LOADED,
    FormsActions.ADD_FORM_VERSION_COMPLETED,
    FormsActions.FETCH_ERROR,
    FormsActions.UPLOAD_EXCEL_COMPLETED,
    FormsActions.DOWNLOAD_ERROR_EXCEL_COMPLETED,
  ];
  public formRules = {
    formName: [
      this.helperService.getRequiredFormRule(),
      this.helperService.getMaxLengthFormRule(NAME_COLUMN_MAX_LENGTH),
      this.helperService.getMinLengthFormRule(1),
    ],
    formId: [this.helperService.getRequiredFormRule()],
    legacyId: [this.helperService.getMaxLengthFormRule(30)],
    formApprovalWorkFlow: [this.helperService.getRequiredFormRule()],
    entryReviewWorkFlow: [this.helperService.getRequiredFormRule()],
    activityType: [this.helperService.getRequiredFormRule()],
    checkInMechanism: [this.helperService.getRequiredFormRule()],
  };
  public formForm: IFormForm = {
    formName: _.cloneDeep(defaultFormValue),
    formApprovalWorkFlow: _.cloneDeep(defaultFormValue),
    entryReviewWorkFlow: _.cloneDeep(defaultFormValue),
    formId: _.cloneDeep(defaultFormValue),
    versionNumber: _.cloneDeep(defaultFormValue),
    legacyId: _.cloneDeep(defaultFormValue),
    activityType: _.cloneDeep(defaultFormValue),
    issuedDate: _.cloneDeep(defaultFormValue),
    useCheckIn: _.cloneDeep(defaultFormValue),
  };
  public rebuildEmitter: Subject<object> = new Subject<object>();
  public cloneRebuildEmitter: Subject<object> = new Subject<object>();
  public defaultFileData: IFile = {
    chartData: [],
    type: FileTypes.PDF,
    autoFill: true,
    searchSelectors: [],
    formSelectors: [],
  };
  public file: IFile = _.cloneDeep(this.defaultFileData);
  public document: IDocument = _.cloneDeep(this.documentGeneratorService.defaultDocumentData);

  public isPrintAllowedSubject: Subject<boolean> = new Subject<boolean>();
  public initializePrintSubject: Subject<boolean> = new Subject<boolean>();
  public nameOfUser: string = '';
  public titleOfUser: string | null = '';
  public roleOfUser: string | undefined = '';
  public clientFormActivationFlow: EFormActivationFlow | null = null;
  public EFormActivationFlow: typeof EFormActivationFlow = EFormActivationFlow;
  public bannerConfiguration: IBanner = {
    header: {
      title: '',
      uri: '',
    },
    footer: {
      uri: 'assets/images/scw-logo-2.png',
      doubleImage: true,
    },
  };
  public bannerConfig: IDocumentBanner = {
    header: {
      title: '',
      uri: '',
    },
    footer: {
      uri: 'assets/images/scw-logo-2.png',
      doubleImage: true,
    },
  };

  public dragObserver = new MutationObserver(this.formIOLinkObserverSelector.bind(this));
  public linkObserver = new MutationObserver(this.getLink.bind(this));
  public previewLinkObserver = new MutationObserver(this.getLink.bind(this));
  public dropdownMenuItems: ScwMatDropdownMenuItem[] = [
    {
      key: 'manually',
      text: this.translate.instant('excel.dropdownElement.manually'),
    },
    {
      key: 'bulk-upload',
      text: this.translate.instant('excel.dropdownElement.bulkUpload'),
    },
  ];
  public excelErrorMessage: string = '';
  public excelErrorHeader: string = '';
  private addFormPayload!: IFormActionPayload;
  private readonly excelUploadLimit: number = DEFAULT_EXCEL_UPLOAD_LIMIT_FOR_LOGBOOK_AND_FORM;
  public formsFromExcel!: IFormExcel | null;
  private excelFiles!: File | undefined;
  private excelDateFormat$!: string;
  private excelTimeFormat$!: string;
  private excelDownloadPressed: boolean = false;
  private timezone$: string = '';
  private dateTimeFormat$: string = '';
  private excelIsAllFailure: boolean = false;
  private isExcelUploadFailure: boolean = false;
  public userInformation!: IUserShortInfo;
  public successCount: number = 0;
  private readonly actionsToNotDispatchHideLoader: string[] = [
    FormsActions.SUBMIT_FORM_LOADED,
    FormsActions.CLONE_FORM_COMPLETED,
    FormsActions.DELETE_FORM_LOADED,
    FormsActions.ACTIVATE_FORM_LOADED,
    FormsActions.FLAG_TO_BE_ARCHIVE_FORM_LOADED,
    FormsActions.ARCHIVE_FORM_LOADED,
    FormsActions.EDIT_FORM_COMPLETED,
    FormsActions.ADD_FORM_VERSION_COMPLETED,
  ];
  public readonly compareHeaders: ICompareHeader[] = [
    {
      label: 'myTasks.formTasksShowModal.before',
      innerLabel: 'myTasks.formTasksShowModal.preDefinedFields',
      innerLabelIconCls: 'fas fa-file-alt',
      value: 'before',
    },
    {
      label: 'myTasks.formTasksShowModal.after',
      innerLabel: 'myTasks.formTasksShowModal.preDefinedFields',
      innerLabelIconCls: 'fas fa-file-alt',
      value: 'after',
    },
  ];
  public compareFields: ICompareField[] = [];
  public compareFormio!: ICompareFormio;
  public isFlagToArchiveDisabled: boolean = false;
  private userId$!: number;
  public isSelectAll: boolean = true;
  public isDraftRecordSelected: boolean = false;
  public isApprovedOrCompletedRecordSelected: boolean = false;
  public selectedStatusesIncompatibleErrorMessage: string = this.translate.instant(
    'bulkAction.error.incompatibleStatusesSelected',
    { records: this.translate.instant('bulkAction.form.plural') },
  );
  public noSelectableRecord: boolean = false;
  public readonly versionRecordCheckboxIdPrefix: string = 'form-version-checkbox-';

  public ngOnInit(): void {
    this.store.dispatch(new FormsActions.GetActiveMasterDataLoading(false));
    this.store.dispatch(new FormsActions.GetWorkflowData());
    this.store.dispatch(new FormsActions.CheckPassedIssueDate());
    this.store.dispatch(ActivityTypeActions.ActivityTypesDataLoading(fetchAllDataDefaultTableQuery));
    this.subscriptions.push(
      this.store.select('clientStore').subscribe((state: IClientSettingsState) => {
        this.isPdfEnhancement = state.clientCommonInformation?.isPdfEnhancement ?? true;
        this.isAskForPinOnAction = state.clientCommonInformation?.askForPinOnAction;
      }),
      this.store.select('user').subscribe((state: User) => {
        this.userId$ = Number(state.userId);
        this.userTimeFormat = state.dateFormat;
        this.timezone$ = state.timezone ?? '';
        this.minDate = moment().tz(this.timezone$);
        this.dateTimeFormat$ = HelperService.userDateTimeFormat ?? '';
        this.nameOfUser = `${state.firstName} ${state.lastName}`;
        this.titleOfUser = state.title ?? '';
        this.roleOfUser = state.role?.name ?? '';
        this.clientFormActivationFlow = state.formActivationFlow;
        this.store.dispatch(new PageConfigurationActions.UserFormSettingsPageConfigurationLoading(this.userId$));

        if (state.locale !== '') {
          this.excelDateFormat$ = excelDateFormat[state.locale as keyof IExcelDateFormat];
          this.excelTimeFormat$ = excelTimeFormat[state.locale as keyof typeof excelTimeFormat];
        }

        if (state.clientLogoId) {
          this.bannerConfiguration.footer = {
            ...this.bannerConfiguration.footer,
            uri: `${this.baseUrl}/files/clients/${state.clientLogoId}`,
          };
        }

        this.loggedInUserUsername = state.username;
      }),
      this.storeActions
        .pipe(ofType(FormsActions.CHECK_PASSED_ISSUE_DATE_COMPLETED))
        .subscribe((response: FormsActions.CheckPassedIssueDateCompleted) => {
          if (response.payload.data.length) {
            this.passedIssueDateForms = response.payload.data;
            this.showPassedIssueDateModal();
          }
        }),
      this.storeActions
        .pipe(ofType(FormsActions.SET_TABLE_SETTINGS))
        .subscribe((response: FormsActions.SetTableSettings) => {
          this.formTableHeaders = _.cloneDeep(response.payload);
          this.selectedFormTableHeaders = _.cloneDeep(
            this.formTableHeaders.filter((item: ITableHeader) => item.selected),
          );

          this.selectedFormTableHeaders = this.bulkActionService.appendCheckboxHeader(this.selectedFormTableHeaders);
        }),
      this.storeActions
        .pipe(ofType(PageConfigurationActions.USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADED))
        .subscribe((response: PageConfigurationActions.UserFormSettingsPageConfigurationLoaded) => {
          this.tableConfiguration = _.get(response.payload.data, '[0].meta', null);

          if (_.isNull(this.tableConfiguration)) {
            this.selectedFormTableHeaders = _.cloneDeep(
              this.formTableHeaders.filter((item: ITableHeader) => item.selected),
            );

            return;
          }

          const tableHeader: ITableHeader[] = [];
          let unMatchedIndexCount: number = 1;

          this.formTableHeaders.forEach((table: ITableHeader) => {
            const index: number = this.tableConfiguration.findIndex(
              (config: IConfigurationMeta): boolean => config.value === table.value,
            );

            if (index === -1) {
              tableHeader[this.formTableHeaders.length - unMatchedIndexCount] = {
                ...table,
                selected: false,
              };
              unMatchedIndexCount += 1;
            } else {
              tableHeader[index] = table;
            }
          });

          this.formTableHeaders = tableHeader;
          this.selectedFormTableHeaders = tableHeader.filter((item: ITableHeader) => item.selected);
          this.selectedFormTableHeaders = this.bulkActionService.appendCheckboxHeader(this.selectedFormTableHeaders);
        }),
      this.storeActions
        .pipe(ofType(FormsActions.ALL_FORM_VERSIONS_DATA_LOADED))
        .subscribe((response: FormsActions.AllFormVersionsDataLoaded) => {
          this.selectOrUnselectAll(false);

          this.formTableData$ = _.cloneDeep(response.payload.data).map((item: IFormVersionSettings) => {
            return {
              ...item,
              issuedDateFormatted: item.issuedDate ? HelperService.formatDateTimeWithLineBreak(item.issuedDate) : null,
              activatedAtFormatted: item.activatedAt
                ? HelperService.formatDateTimeWithLineBreak(item.activatedAt)
                : null,
              activityTypeFormatted: ComponentUtilities.findOneOptionForSelect(
                this.formActivityTypeItems$,
                item.activityType,
                'id',
              )[0].name,
              formIdFormatted: item.form.formId,
            };
          });
          this.formTablePageCount$ = response.payload.total ?? 1;

          if (this.isSelectedFormOngoing && ['archive', 'activate'].indexOf(this.formActionType) !== -1) {
            this.modal.open(this.ongoingInformationModal, {
              keyboard: false,
              backdrop: 'static',
              windowClass: this.scwModalSm,
            });
          }

          if (this.selectedForm && this.allVersionOn) {
            this.prepareAllVersionsModal(this.selectedForm.id);
            this.allVersionOn = false;
          }

          this.setNoSelectableRecord();
        }),
      this.storeActions
        .pipe(ofType(FormsActions.GET_SPECIFIC_FORM_DATA_LOADED))
        .subscribe((response: FormsActions.GetSpecificFormDataLoaded) => {
          this.workflowData$ = this.helperService.formatWorkflowDataList(response.payload.workflows);
          this.formForm.formId.value = this.translate.instant('settings.forms.idGenerateAfterSave');
          this.formForm.versionNumber.value = this.translate.instant('settings.forms.versionNumberGenerateAfterSave');

          if (
            this.formActionType !== 'add-modal-open' &&
            this.formActionType !== 'add-new-version' &&
            this.formActionType !== 'add'
          ) {
            this.setPredefinedFormFields(response.payload.formVersion);
            this.form.components = _.cloneDeep(response.payload.formVersion.meta);
            this.formClone.components = _.cloneDeep(response.payload.formVersion.meta);
            this.formCloneInitial.components = _.cloneDeep(response.payload.formVersion.meta);
            this.rebuildEmitter.next(this.formIoConfiguration);
          }

          if (this.formActionType === 'all-version-show') {
            this.formClone.components = _.cloneDeep(response.payload.formVersion.meta);
            this.cloneRebuildEmitter.next(this.formIoConfiguration);
          }

          // This set time out was has to be done since form io has no 'I am ready to use' emitter and since
          // we need to use drag and drop in touch devices it is required to prevent scrolling while drag and drop.
          setTimeout(() => {
            const formDropAreaTarget = document.querySelector('.formarea');
            if (formDropAreaTarget) {
              this.dragObserver.observe(formDropAreaTarget, {
                childList: true,
                characterData: true,
              });
            }
            document.querySelectorAll('.formio-form').forEach((ele) => {
              if (ele) {
                this.previewLinkObserver.observe(ele, {
                  subtree: true,
                  childList: true,
                });
              }
            });
            document.querySelectorAll('.drag-copy').forEach((ele) => {
              ele.addEventListener('touchstart', this.disableDrag.bind(this));
              ele.addEventListener('touchend', this.enableDrag.bind(this));
            });
            this.getLink();
          }, 3000);

          this.compareFields = this.formService.setCompareFields(
            this.formForm,
            this.adjustPreviousVersionFields(response.payload.previousVersion),
            this.workflowData$,
          );

          this.compareFormio = {
            before: response.payload.previousVersion
              ? {
                  name: response.payload.previousVersion.name,
                  form: {
                    components: response.payload.previousVersion.meta,
                  },
                }
              : undefined,
            after: {
              name: this.targetItem.name,
              form: {
                components: this.targetItem.meta,
              },
            },
          };
        }),
      this.storeActions
        .pipe(ofType(FormsActions.WORKFLOW_STEP_DATA_LOADED))
        .subscribe((response: FormsActions.GetWorkflowStepDataLoaded) => {
          this.workflowStepData$ = _.cloneDeep(response.payload.data).map((item: IWorkflowStepsData) => {
            let dueDateString: string = '';

            if (typeof item.dueDateType === 'number') {
              dueDateString = this.dueDateType[item.dueDateType].name;
            }

            return {
              ...item,
              expirationDate: dueDateString ? `${item.dueDate} ${dueDateString}` : dueDateString,
              roleFormatted: item.role?.name,
            };
          });
        }),
      this.storeActions
        .pipe(ofType(FormsActions.GET_FORM_MASTER_DATA_COMPLETED))
        .subscribe((response: FormsActions.GetMasterDataLoaded) => {
          if (this.formActionType === 'excel' && this.excelDownloadPressed) {
            this.store.dispatch(
              new FormsActions.DownloadExcel(
                response.payload.data,
                this.workflowData$,
                this.formActivityTypeDropdownItems$,
              ),
            );
            this.excelDownloadPressed = false;
            return;
          }

          if (this.formActionType === 'add') {
            this.formVersionModalRef = this.modal.open(this.formModal, {
              keyboard: false,
              backdrop: 'static',
              windowClass: this.scwModalXlWithDragSelector,
            });
          }

          const findSelectedWorkflow: IWorkflow | undefined = this.workflowData$.find(
            (item: IWorkflow) => item.id === this.targetItem?.workflowId,
          );
          const findSelectedWorkflowEntry: IWorkflow | undefined = this.workflowData$.find(
            (item: IWorkflow) => item.id === this.targetItem?.workflowEntriesId,
          );
          this.isHasNotWorkflow =
            !findSelectedWorkflow ||
            !findSelectedWorkflowEntry ||
            (!findSelectedWorkflow.isActive && findSelectedWorkflow.id !== 1) ||
            (!findSelectedWorkflowEntry.isActive && findSelectedWorkflowEntry.id !== 1);
          this.isMasterMismatch =
            this.targetItem?.fieldVersionId !== this.activeMasterDataId$ && this.activeMasterDataId$ !== undefined;

          if (
            (this.formActionType === 'edit-modal-open' && this.isMasterMismatch) ||
            (this.formActionType === 'submit' && (this.isMasterMismatch || this.isHasNotWorkflow))
          ) {
            this.activeMasterDataData$ = response.payload.data;
            this.showMasterDataMismatchModal();

            return;
          }

          if (this.formActionType === 'submit') {
            this.modalRef = this.modal.open(this.submitModal, {
              keyboard: false,
              backdrop: 'static',
              windowClass: this.scwModalSm,
            });

            return;
          }

          if (
            this.formActionType === 'add-new-version-modal-open' ||
            (this.formActionType === 'edit-modal-open' && this.targetItem?.fieldVersionId === this.activeMasterDataId$)
          ) {
            this.modalContent = ComponentUtilities.generateUserDefinedModalContent(
              _.cloneDeep(response.payload.data.fields),
            );
            this.beforeActionPreparerActionSubject.next(!this.actionTypesWithoutReason.includes(this.formActionType));
            return;
          }

          if (this.isMasterDataMisMatched && this.formActionType === 'edit-modal-open') {
            this.beforeActionPreparerActionSubject.next(false);
            this.activeMasterDataData$ = _.cloneDeep(response.payload.data);
            return;
          }

          if (this.formActionType === 'all-version-show') {
            this.masterDataLoadedShowAllVersions(response);
          } else if (!this.isMasterDataMisMatched) {
            this.modalContent = ComponentUtilities.generateUserDefinedModalContent(
              _.cloneDeep(response.payload.data.fields),
            );
            this.setUserDefinedFields();
            this.activeMasterDataData$ = _.cloneDeep(response.payload.data);
          } else if (this.formActionType !== 'add') {
            this.masterDataMismatchModalRef?.close();
            this.modalContent = this.helperService.compareOldNewMasterData(
              response.payload.data,
              this.activeMasterDataData$,
              this.selectedForm,
            );
            this.formVersionModalRef = this.modal.open(this.formModal, {
              keyboard: false,
              backdrop: 'static',
              windowClass: this.scwModalXlWithDragSelector,
            });
            this.isMasterDataMisMatched = false;
          }
        }),
      this.storeActions
        .pipe(
          ofType(
            FormsActions.SUBMIT_FORM_LOADED,
            FormsActions.DELETE_FORM_LOADED,
            FormsActions.CLONE_FORM_COMPLETED,
            FormsActions.ARCHIVE_FORM_LOADED,
            FormsActions.FLAG_TO_BE_ARCHIVE_FORM_LOADED,
            FormsActions.ADD_FORM_COMPLETED,
            FormsActions.EDIT_FORM_COMPLETED,
            FormsActions.ACTIVATE_FORM_LOADED,
            FormsActions.ADD_FORM_VERSION_COMPLETED,
          ),
        )
        .subscribe((thisAction: Action) => {
          this.helperService.showToastMessage(
            true,
            this.translate.instant('general.success'),
            this.translate.instant('general.changesSavedSuccessfully'),
          );
          this.defaultModalRef?.close();
          this.formVersionModalRef?.close();
          this.historyModalRef?.close();
          this.modalRef?.close();
          this.masterDataMismatchModalRef?.close();

          if (thisAction.type === FormsActions.DELETE_FORM_LOADED) {
            this.targetItem = _.find(this.formVersionData$, {
              isActive: true,
              approvalStepPosition: EApprovalStatuses.ACTIVE,
            }) as IFormVersion;
          }

          if (thisAction.type === FormsActions.ADD_FORM_COMPLETED) {
            this.showScopeModal();
          }

          this.store.dispatch(
            new FormsActions.AllFormVersionsDataLoading(
              this.selectedStatusFilters,
              _.cloneDeep(this.tableQuery),
              !this.actionsToNotDispatchHideLoader.some((action) => {
                return action === thisAction.type;
              }),
            ),
          );
          this.allVersionOn = true;
        }),
      this.storeActions
        .pipe(ofType(FormsActions.ACTIVE_MASTER_DATA_LOADED))
        .subscribe((response: FormsActions.GetActiveMasterDataLoaded) => {
          this.activeMasterDataId$ = _.cloneDeep(response.payload.data[0].id);
        }),
      this.storeActions
        .pipe(ofType(FormsActions.WORKFLOW_DATA_LOADED))
        .subscribe((response: FormsActions.GetWorkflowDataLoaded) => {
          this.workflowData$ = this.helperService.formatWorkflowDataList(response.payload.data);
        }),
      this.storeActions
        .pipe(ofType(FormsActions.GET_FORM_VERSION_LOADED))
        .subscribe((response: FormsActions.GetFormVersionsLoaded) => {
          this.formVersionData$ = _.cloneDeep(response.payload.data).map((item: IFormVersion) => {
            this.isSelectedFormOngoing =
              this.isSelectedFormOngoing || Boolean(item.ongoingForms && item.ongoingForms.length);
            this.selectedFormActivationInProgress =
              this.selectedFormActivationInProgress || Boolean(item.activationInProgress);
            this.formVersionsCount$ = _.isNil(response.payload.total) ? 0 : response.payload.total;

            if (this.isSelectedFormOngoing && !this.selectedFormOngoingForms.length) {
              this.selectedFormOngoingForms = item.ongoingForms ?? [];
            }

            return {
              ...item,
              issuedDateFormatted: item.issuedDate ? HelperService.formatDateTimeWithLineBreak(item.issuedDate) : null,
              activityTypeFormatted: ComponentUtilities.findOneOptionForSelect(
                this.formActivityTypeItems$,
                item.activityType,
                'id',
              )[0]?.name,
              activatedAtFormatted: item.activatedAt
                ? HelperService.formatDateTimeWithLineBreak(item.activatedAt)
                : null,
            };
          });
          this.isDraftExists = Boolean(
            this.formVersionData$.filter(
              (item: IFormVersion): boolean =>
                item.approvalStepPosition === EApprovalStatuses.NOT_SUBMITTED ||
                item.approvalStepPosition >= EApprovalStatuses.SUBMITTED ||
                item.approvalStepPosition === EApprovalStatuses.SEND_BACK,
            ).length,
          );
          this.isFlagToArchiveDisabled = this.formVersionData$.some(
            (item: IFormVersion): boolean =>
              item.approvalStepPosition >= EApprovalStatuses.SUBMITTED ||
              item.approvalStepPosition === EApprovalStatuses.SEND_BACK,
          );
        }),
      this.storeActions
        .pipe(ofType(FormsActions.GET_FORM_HISTORY_LOADED))
        .subscribe((response: FormsActions.GetFormHistoryLoaded) => {
          this.formHistoryData$ = _.cloneDeep(response.payload.data);
        }),
      this.storeActions
        .pipe(ofType(MainActions.CHECK_PERMISSION_COMPLETED))
        .subscribe((response: MainActions.CheckPermissionCompleted) => {
          if (!response.payload.success) {
            this.store.dispatch(new AppActions.HideLoader());
            return;
          }

          switch (this.formActionType) {
            case 'add-modal-open':
              this.showFormModal('add', this.formModal);
              this.store.dispatch(
                new FormsActions.GetSpecificFormDataLoading(null, null, response.issuer ?? undefined),
              );
              break;
            case 'edit-modal-open':
              this.store.dispatch(
                new FormsActions.GetSpecificFormDataLoading(
                  this.selectedForm?.form?.id ?? null,
                  this.targetItem?.id ?? null,
                  response.issuer ?? undefined,
                  false,
                ),
              );

              this.fetchMasterDataOrCompare(true);
              this.showFormModal('edit', this.formModal);
              break;
            case 'add-new-version-modal-open':
              if (!this.isDraftExists && this.selectedForm) {
                this.formActionType = 'add-new-version';
                this.resetFormValues();
                this.store.dispatch(
                  new FormsActions.GetSpecificFormDataLoading(
                    this.selectedForm?.form.id,
                    this.targetItem?.id,
                    response.issuer ?? undefined,
                  ),
                );
                this.formVersionModalRef = this.modal.open(this.formModal, {
                  keyboard: false,
                  backdrop: 'static',
                  windowClass: this.scwModalXl,
                });
                return;
              }
              this.showDraftVersionExistsModal();
              break;
          }
        }),
      this.storeActions
        .pipe(ofType(FormsActions.UPLOAD_EXCEL_COMPLETED))
        .subscribe((response: FormsActions.UploadExcelCompleted) => {
          this.successCount = 0;

          if (this.formsFromExcel?.formData) {
            this.successCount = this.formsFromExcel.formData.length - response.payload.length;
          }

          this.excelIsAllFailure = this.successCount === 0;

          if (response.success) {
            ComponentUtilities.showExcelToastMessage(
              this.formsFromExcel?.formData.length,
              this.toast,
              this.translate,
              'toast.message.property.forms',
            );

            if (!this.excelIsAllFailure) {
              this.showScopeModal();
            }
          } else {
            this.store.dispatch(new AppActions.ShowLoader());
            this.store.dispatch(
              new FormsActions.DownloadErrorExcel(
                response.payload,
                this.activeMasterDataData$,
                this.workflowData$,
                this.formActivityTypeItems$,
                true,
              ),
            );

            this.isExcelUploadFailure = true;

            ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
            this.excelErrorHeader = this.translate.instant('excel.dropdownElement.bulkUpload');
            this.excelErrorMessage = this.translate.instant('excel.messages.fileHasErrors');
          }
          this.store.dispatch(
            new MainActions.CreateManualLog(
              EManualLogActionType.EXCEL_IMPORT,
              EManualLogObjectContentModel.FORM,
              undefined,
              undefined,
              response.issuerAndReason?.issuer,
              response.issuerAndReason?.reason,
            ),
          );
          this.store.dispatch(
            new FormsActions.AllFormVersionsDataLoading(this.selectedStatusFilters, _.cloneDeep(this.tableQuery)),
          );
        }),
      zip([
        this.storeActions.pipe(ofType(FormsActions.GET_SPECIFIC_FORM_DATA_LOADED)),
        this.storeActions.pipe(ofType(FormsActions.GET_FORM_MASTER_DATA_COMPLETED)),
      ]).subscribe(() => {
        this.store.dispatch(new AppActions.HideLoader());
      }),
      zip([
        this.storeActions.pipe(ofType(FormsActions.CHECK_PASSED_ISSUE_DATE_COMPLETED)),
        this.storeActions.pipe(ofType(FormsActions.ACTIVE_MASTER_DATA_LOADED)),
        this.storeActions.pipe(ofType(FormsActions.WORKFLOW_DATA_LOADED)),
        this.storeActions.pipe(ofType(FormsActions.ALL_FORM_VERSIONS_DATA_LOADED)),
      ]).subscribe(() => {
        this.store.dispatch(new AppActions.HideLoader());
      }),
      this.storeActions.pipe(ofType(FormsActions.DOWNLOAD_EXCEL_COMPLETED)).subscribe(() => {
        this.store.dispatch(
          new MainActions.CreateManualLog(
            EManualLogActionType.EXCEL_TEMPLATE_DOWNLOAD,
            EManualLogObjectContentModel.FORM,
          ),
        );
      }),
      this.storeActions.pipe(ofType(FormsActions.DOWNLOAD_ERROR_EXCEL_COMPLETED)).subscribe(() => {
        this.store.dispatch(
          new MainActions.CreateManualLog(
            EManualLogActionType.EXCEL_DOWNLOAD,
            EManualLogObjectContentModel.FORM,
            undefined,
            undefined,
            this.issuerAndReason?.issuer,
            this.issuerAndReason?.reason,
          ),
        );
      }),
      this.storeActions
        .pipe(ofType(UsersActions.GET_USER_SHORT_INFORMATION_COMPLETED))
        .subscribe((response: UsersActions.GetUserShortInformationCompleted) => {
          this.userInformation = response.payload.data;

          this.addFormPayload = {
            ...this.addFormPayload,
            scopeId: this.userInformation.formScopeId,
          };

          if (this.formActionType === 'add') {
            this.store.dispatch(new FormsActions.AddForm(this.addFormPayload, this.issuerAndReason?.issuer));
          } else if (this.formActionType === 'excel') {
            if (!this.formsFromExcel) {
              return;
            }

            const excelData: IFormCreate[] = this.prepareFormExcelData(this.formsFromExcel, this.issuerAndReason);

            this.store.dispatch(
              new FormsActions.UploadExcel(
                excelData,
                this.workflowData$,
                this.issuerAndReason,
                this.activeMasterDataData$,
                this.userInformation.formScopeId,
              ),
            );
          }
        }),
      this.storeActions.pipe(ofType(FormsActions.FETCH_ERROR)).subscribe((errorRes: FormsActions.FetchError) => {
        switch (errorRes.errorType) {
          case EFormActionsErrorType.LAST_ITEM_IN_SCOPE:
            const scopes: string[] | null = _.get(errorRes, 'payload.error.data.scopes', null);

            if (!scopes) {
              break;
            }

            let scopeNames: string;

            if (scopes.length === 1) {
              scopeNames = scopes[0];
            } else {
              scopeNames = scopes.slice(0, -1).join(', ');
              scopeNames = `${scopeNames} and ${scopes.slice(-1)}`;
            }

            this.toast.error(
              this.translate.instant('apiErrorMessages.formIsLastItemInScope', { scopeNames }),
              this.translate.instant('general.error'),
              {
                closeButton: false,
                progressBar: true,
                positionClass: 'toast-bottom-right',
              },
            );
            this.modalRef?.close();
            break;
        }

        this.selectOrUnselectAll(false);
        this.defaultModalRef?.close();
      }),
      this.storeActions
        .pipe(ofType(FormsActions.BULK_SUBMIT_FORMS_COMPLETED, FormsActions.BULK_ACTIVATE_FORMS_COMPLETED))
        .subscribe(
          (response: FormsActions.BulkSubmitFormsCompleted | FormsActions.BulkActivateFormsCompleted): void => {
            this.selectOrUnselectAll(false);
            const bulkActionType: TBulkActions =
              response.type === FormsActions.BULK_SUBMIT_FORMS_COMPLETED ? 'bulk-submit' : 'bulk-activate';

            this.bulkActionService.handleBulkActionSuccess(
              response.response,
              'form',
              bulkActionType,
              this.bulkErrorModalComponent,
            );

            this.store.dispatch(
              new FormsActions.AllFormVersionsDataLoading(this.selectedStatusFilters, _.cloneDeep(this.tableQuery)),
            );
          },
        ),
      this.storeActions
        .pipe(ofType(ActivityTypeActions.ActionTypes.ACTIVITY_TYPE_DATA_LOADED))
        .subscribe((response: { payload: IGetManyResponse<IActivityType> }): void => {
          this.formActivityTypeItems$ = response.payload.data.map((row: IActivityType): ISelect<number, string> => {
            return {
              id: row.id,
              name: row.name,
            };
          });

          this.formActivityTypeDropdownItems$ = _.orderBy(
            _.filter(response.payload.data, { isActive: true }).map((row: IActivityType): ISelect<number, string> => {
              return {
                id: row.id,
                name: row.name,
              };
            }),
            (value: ISelect<number, string>) => value.name.toUpperCase(),
          );
        }),
    );
  }

  public onFiltersChanged(filter: IFormSettingsFilterOutput): void {
    this.selectedStatusFilters = filter.selectedStatusId;
    this.tableQuery = { ...this.tableQuery, versionType: filter.versionType, page: 1 };
    this.selectOrUnselectAll(false);

    if (this.filtersReady) {
      this.store.dispatch(
        new FormsActions.AllFormVersionsDataLoading(filter.selectedStatusId, _.cloneDeep(this.tableQuery)),
      );
    }
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.tableQuery = { ...this.tableQuery, search: searchText.trim().toLowerCase() };

    this.selectOrUnselectAll(false);
    this.store.dispatch(
      new FormsActions.AllFormVersionsDataLoading(this.selectedStatusFilters, _.cloneDeep(this.tableQuery)),
    );
  }

  public showFormModal(
    type: FormActionsType,
    templateRef: TemplateRef<any>,
    id: number = 0,
    isViewAllHistory: boolean = false,
  ): void {
    this.formActionType = type;
    if (id) {
      this.targetItem = _.find(this.formVersionData$, { id }) as IFormVersion;
    }

    this.resetFormValues();
    switch (type) {
      case 'add':
        this.selectedForm = null;
        this.store.dispatch(new FormsActions.GetActiveMasterDataLoading());
        this.store.dispatch(new FormsActions.GetSpecificFormDataLoading());
        break;
      case 'all-version':
        this.prepareAllVersionsModal(id);
        this.allVersionsModalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalXl,
        });
        break;
      case 'add-new-version':
        if (this.isDraftExists) {
          this.showDraftVersionExistsModal();
          return;
        }
        this.store.dispatch(new FormsActions.GetSpecificFormDataLoading(null, null));
        this.formVersionModalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalXlWithDragSelector,
        });
        break;
      case 'edit':
        this.disableAllFields = false;

        if (this.selectedForm && !this.isMasterDataMisMatched) {
          this.setUserDefinedFields();
          this.formVersionModalRef = this.modal.open(templateRef, {
            keyboard: false,
            backdrop: 'static',
            windowClass: this.scwModalXlWithDragSelector,
          });
        }
        break;
      case 'submit':
        this.store.dispatch(new FormsActions.GetActiveMasterDataLoading());
        break;
      case 'all-version-show':
        this.previousVersion = _.find(this.formVersionData$, {
          versionNumber: this.targetItem.versionNumber - 1,
          formId: this.targetItem.formId,
        });
        this.disableAllFields = true;
        this.store.dispatch(
          new FormsActions.GetSpecificFormDataLoading(
            this.selectedForm?.formId ?? null,
            this.targetItem?.id ?? null,
            undefined,
            false,
            this.previousVersion?.id,
            true,
          ),
        );

        this.store.dispatch(
          new FormsActions.GetMasterDataLoading(this.targetItem?.fieldVersionId ?? this.activeMasterDataId$, false),
        );

        this.formVersionModalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalXl,
        });
        break;
      case 'history':
        const versionIds: number[] = [];
        let isDataAvailable: boolean = false;
        this.formHistoryData$ = [];
        this.getVersionHistoryParams = {};
        this.isAllViewHistory = isViewAllHistory;

        if (isViewAllHistory) {
          this.historyTableHeader = this.allHistoryTableHeader;
          this.formVersionData$.forEach((data: IFormVersion) => versionIds.push(data.id));
          this.getVersionHistoryParams = {
            formId: this.selectedForm?.form?.id,
          };
          isDataAvailable = !_.isNil(this.getVersionHistoryParams.formId);
          this.recordHeader = this.translate.instant('settings.forms.modal.formHistory.viewAllHeader', {
            formName: this.formVersionData$[0]?.name,
          });
        } else if (this.targetItem) {
          this.historyTableHeader = this.singleHistoryTableHeader;
          versionIds.push(this.targetItem?.id);
          this.recordHeader = this.translate.instant('settings.forms.modal.formHistory.header', {
            formName: this.targetItem?.name,
          });
          this.getVersionHistoryParams = {
            versionIds,
          };
          isDataAvailable = versionIds.length > 0;
        }

        if (isDataAvailable) {
          this.store.dispatch(
            new FormsActions.GetFormHistoryLoading(
              this.getVersionHistoryParams,
              this.selectedForm?.form.id,
              isViewAllHistory,
            ),
          );
          this.historyModalRef = this.modal.open(templateRef, {
            keyboard: false,
            backdrop: 'static',
            windowClass: this.scwModalXl,
          });
        }

        break;
      case 'clone':
        if (this.isDraftExists) {
          this.showDraftVersionExistsModal();
          return;
        }
        this.modalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalSm,
        });
        break;
      case 'delete':
        this.modalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalSm,
        });
        break;
      default:
        this.defaultModalRef = this.modal.open(templateRef, {
          keyboard: false,
          backdrop: 'static',
          windowClass: this.scwModalSm,
        });
    }
  }

  public onDataRequestHandler(params: IDatatableOutputParameter): void {
    const query: IFormTableQuery = {
      page: 1,
      rowsPerPage: 10,
      search: '',
      sort: {
        column: 'form',
        type: '-',
      },
      versionType: 1,
    };

    if (params.page) {
      query.page = params.page;
    }

    if (params.rowsPerPage) {
      query.rowsPerPage = Number(params.rowsPerPage);
    }

    if (this.searchBoxText && this.searchBoxText !== '') {
      query.search = this.searchBoxText;
    }

    if (params.sort && query.sort && params.sort.type !== 'none') {
      query.sort.column = this.setSortParameter(params.sort.column);

      if (params.sort.type === 'ascending') {
        query.sort.type = '';
      }

      if (params.sort.type === 'descending') {
        query.sort.type = '-';
      }
    }
    query.versionType = this.tableQuery.versionType;

    this.selectOrUnselectAll(false);
    this.tableQuery = query;
    this.store.dispatch(
      new FormsActions.AllFormVersionsDataLoading(this.selectedStatusFilters, _.cloneDeep(this.tableQuery)),
    );
  }

  public onFormVersionsDataRequest(params: IDatatableOutputParameter): void {
    this.getFormVersionsParams = {
      ...this.getFormVersionsParams,
      page: params.page,
      rowsPerPage: params.rowsPerPage,
    };
    this.loadAllFormVersions();
  }

  public checkFormFieldValidity(isValid: boolean): void {
    this.isFormIoKeysUnique = this.checkFormIoComponentApiKeyUniqueness();
    this.checkFormIoComponentValueUniqueness();
    this.isPredefinedFieldsValid = isValid;
    this.actionSubject.next(true);
  }

  public onWorkFlowChanged(selectedWorkFlow: ISelect<number, string>[], property: string): void {
    if (!selectedWorkFlow.length) {
      _.set(this, property, true);
      return;
    }

    _.set(this, property, selectedWorkFlow[0].id === 1);
  }

  public onShowWorkFlowStepClicked(
    selectedWorkFlowItem: ISelect<number, string>[] | null,
    templateRef: TemplateRef<any>,
  ): void {
    this.store.dispatch(new FormsActions.GetWorkflowStepData(_.get(selectedWorkFlowItem, '[0].id', null)));
    this.workFlowStepsModalRef = this.modal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalMd,
    });
  }

  public sendFormToAPI(isValid: boolean): void {
    if (
      !isValid ||
      !this.isPredefinedFieldsValid ||
      !this.isFormIoKeysUnique ||
      !this.isSelectableComponentValueUnique
    ) {
      return;
    }

    this.beforeActionPreparerActionSubject.next(this.formActionType !== 'add');
  }

  public onIssuerActionClicked(type: FormActionsType, id: number | null = null): void {
    this.formActionType = type;
    this.isMasterDataMisMatched = false;

    if (id) {
      this.targetItem = _.find(this.formVersionData$, { id }) as IFormVersion;
    }

    if ((type === 'clone' || type === 'add-new-version-modal-open') && this.isDraftExists) {
      this.showDraftVersionExistsModal();
      return;
    }

    if (type === 'add-new-version-modal-open' || type === 'edit-modal-open') {
      this.store.dispatch(new FormsActions.GetActiveMasterDataLoading());
    }

    if (type !== 'edit-modal-open' && type !== 'add-new-version-modal-open') {
      this.beforeActionPreparerActionSubject.next(!this.actionTypesWithoutReason.includes(type));
    }
  }

  public flagToBeArchived(issuerAndReason: IIssuerAndReason): void {
    this.store.dispatch(
      new FormsActions.FlagToBeArchiveFormLoading(this.selectedForm?.form?.id ?? null, issuerAndReason),
    );
  }

  public archiveForm(issuerAndReason: IIssuerAndReason): void {
    this.store.dispatch(new FormsActions.ArchiveFormLoading(this.selectedForm?.form?.id ?? null, issuerAndReason));
  }

  public deleteForm(issuerAndReason: IIssuerAndReason): void {
    this.store.dispatch(
      new FormsActions.DeleteFormLoading(
        this.selectedForm?.form?.id ?? null,
        this.targetItem?.id ?? null,
        issuerAndReason,
      ),
    );
  }

  public submitForm(issuer: IIssuer | null): void {
    this.store.dispatch(
      new FormsActions.SubmitFormLoading(this.selectedForm?.form?.id ?? null, this.targetItem?.id ?? null, issuer),
    );
  }

  public activateForm(issuer: IIssuer | null): void {
    this.store.dispatch(
      new FormsActions.ActivateFormLoading(this.selectedForm?.form?.id ?? null, this.targetItem?.id ?? null, issuer),
    );
  }

  public setPredefinedFormFields(data: IFormVersion): void {
    this.formForm.formId = { ...this.formForm.formId, value: data.form.formId };
    this.formForm.legacyId = { ...this.formForm.legacyId, value: data.legacyId };
    this.formForm.versionNumber = { ...this.formForm.versionNumber, value: String(data.versionNumber) };
    this.formForm.activityType = {
      ...this.formForm.activityType,
      value: ComponentUtilities.findOneOptionForSelect(this.formActivityTypeItems$, data.activityType, 'id'),
    };
    this.formForm.formName = { ...this.formForm.formName, value: data.name };
    this.formForm.entryReviewWorkFlow = {
      ...this.formForm.entryReviewWorkFlow,
      value: ComponentUtilities.findOneOptionForSelect(
        this.workflowData$,
        Number(_.get(data.workflowEntries, 'id', 0)),
        'id',
      ),
    };
    this.formForm.formApprovalWorkFlow = {
      ...this.formForm.formApprovalWorkFlow,
      value: ComponentUtilities.findOneOptionForSelect(this.workflowData$, Number(_.get(data.workflow, 'id', 0)), 'id'),
    };
    this.formForm.issuedDate = {
      ...this.formForm.issuedDate,
      value: {
        startDate: data.issuedDate ? moment(data.issuedDate).tz(this.timezone$) : null,
        endDate: data.issuedDate ? moment(data.issuedDate).tz(this.timezone$) : null,
      },
    };
    this.formForm.useCheckIn = {
      isEnabled: true,
      value: ComponentUtilities.findOneOptionForSelect(this.yesNo, data.useCheckIn ? 1 : 0),
      rules: [],
    };

    if (this.formForm.formApprovalWorkFlow.value?.length && this.formForm.formApprovalWorkFlow.value[0].id !== 1) {
      this.formApprovalDisableEyeIcon = false;
    }

    if (this.formForm.entryReviewWorkFlow.value?.length && this.formForm.entryReviewWorkFlow.value[0].id !== 1) {
      this.entryReviewWorkFlowDisableEyeIcon = false;
    }
  }

  public resetFormValues(
    newParam: { isEnabled: boolean; rules: any[]; value: null } = (this.formForm.activityType =
      _.cloneDeep(defaultFormValue)),
  ): void {
    this.disableAllFields = false;
    this.formApprovalDisableEyeIcon = true;
    this.entryReviewWorkFlowDisableEyeIcon = true;
    this.formForm.formId = _.cloneDeep(defaultFormValue);
    this.formForm.legacyId = _.cloneDeep(defaultFormValue);
    this.formForm.versionNumber = _.cloneDeep(defaultFormValue);
    newParam;
    this.formForm.formName = _.cloneDeep(defaultFormValue);
    this.formForm.issuedDate = _.cloneDeep(defaultFormValue);
    this.formForm.entryReviewWorkFlow = _.cloneDeep(defaultFormValue);
    this.formForm.formApprovalWorkFlow = _.cloneDeep(defaultFormValue);
    this.formForm.useCheckIn = _.cloneDeep(defaultFormValue);
    this.form = {
      components: [],
    };
    this.formClone = {
      components: [],
    };
    this.rebuildEmitter.next(this.formIoConfiguration);
    this.cloneRebuildEmitter.next(this.formIoConfiguration);

    this.modalContent = this.modalContent.map((item) => {
      return {
        ...item,
        inputModel: ['Dropdown', 'DropdownMulti', 'CheckboxMulti'].includes(item.type) ? [] : null,
      };
    });
  }

  public dispatchAction(issuerAndReason: IIssuerAndReason): void {
    this.issuerAndReason = issuerAndReason;

    const username: string | null = !this.isAskForPinOnAction
      ? this.loggedInUserUsername
      : issuerAndReason?.issuer?.username ?? this.loggedInUserUsername;

    switch (this.formActionType) {
      case 'add':
      case 'add-new-version':
      case 'edit':
        const payload = this.createFormActionPayload(issuerAndReason);

        if (this.formActionType === 'add') {
          this.addFormPayload = payload;
          this.store.dispatch(new UsersActions.GetUserShortInformation(username));
        }

        if (this.formActionType === 'add-new-version') {
          this.store.dispatch(
            new FormsActions.AddFormVersion(this.selectedForm?.form.id ?? null, payload, issuerAndReason?.issuer),
          );
        }

        if (this.formActionType === 'edit' && this.selectedForm && this.targetItem) {
          this.store.dispatch(
            new FormsActions.EditForm(
              payload,
              this.selectedForm?.form?.id,
              this.targetItem?.id,
              issuerAndReason?.issuer,
            ),
          );
        }
        break;
      case 'clone':
        if (this.targetItem && this.selectedForm) {
          this.store.dispatch(
            new FormsActions.CloneForm(
              {
                form: this.selectedForm?.form?.id,
                legacyId: this.targetItem?.legacyId,
                activityType: this.targetItem?.activityType,
                workflowId: this.targetItem?.workflowId,
                workflowEntriesId: this.targetItem?.workflowEntriesId,
                fieldVersionId: this.targetItem?.fieldVersionId,
                fieldMeta: this.targetItem?.fieldMeta,
                meta: this.targetItem?.meta,
                criticalFieldCount: this.targetItem?.criticalFieldCount,
                name: this.targetItem?.name,
                reason: issuerAndReason.reason,
                useCheckIn: this.targetItem?.useCheckIn,
              },
              this.selectedForm?.form?.id,
              issuerAndReason.issuer,
            ),
          );
        }
        break;
      case 'delete':
        this.deleteForm(issuerAndReason);
        break;
      case 'submit':
        this.submitForm(issuerAndReason.issuer);
        break;
      case 'activate':
        this.activateForm(issuerAndReason.issuer);
        break;
      case 'archive':
        this.archiveForm(issuerAndReason);
        break;
      case 'flag-to-be-archive':
        this.flagToBeArchived(issuerAndReason);
        break;
      case 'add-modal-open':
      case 'edit-modal-open':
      case 'add-new-version-modal-open':
        this.store.dispatch(new MainActions.CheckPermission(ERights.SETTING_FORM_EDIT, issuerAndReason.issuer, false));
        break;
      case 'excel':
        if (!this.formsFromExcel) {
          return;
        }

        this.store.dispatch(new UsersActions.GetUserShortInformation(username));
        break;
      case 'bulk-submit':
        this.store.dispatch(
          new FormsActions.BulkSubmitForms(_.cloneDeep(this.selectedFormVersionRecordIds), issuerAndReason.issuer),
        );
        break;
      case 'bulk-activate':
        this.store.dispatch(
          new FormsActions.BulkActivateForms(_.cloneDeep(this.selectedFormVersionRecordIds), issuerAndReason.issuer),
        );
        break;
    }
  }

  private showDraftVersionExistsModal(): void {
    this.draftVersionExistsModalRef = this.modal.open(this.draftExistsModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  private showScopeModal(): void {
    this.scopeModal.showModal(
      this.formActionType,
      this.successCount,
      this.userInformation.isAllFormScope,
      this.userInformation.formScopeName,
    );
  }

  public allVersionsModalTableHeaderDecider(): void {
    if (this.selectedForm) {
      this.formVersionTableHeaders = [
        {
          value: 'name',
          name: this.translate.instant('general.datatable.headers.formName'),
          selected: true,
          sortable: false,
        },
        {
          value: 'legacyId',
          name: this.translate.instant('general.datatable.headers.formLegacyId'),
          selected: true,
          sortable: false,
        },
        {
          value: 'versionNumber',
          name: this.translate.instant('general.datatable.headers.versionNumber'),
          selected: true,
          sortable: false,
        },
        {
          value: 'createdBy',
          name: this.translate.instant('general.datatable.headers.createdBy'),
          selected: true,
          sortable: false,
        },
        {
          value: 'activityTypeFormatted',
          name: this.translate.instant('general.datatable.headers.activityType'),
          selected: true,
          sortable: false,
          type: PageConfigurationTypes.TABLE,
        },
        {
          value: 'issuedDateFormatted',
          name: this.translate.instant('general.datatable.headers.issueDate'),
          selected: true,
          sortable: false,
        },
        {
          value: 'activatedAtFormatted',
          name: this.translate.instant('general.datatable.headers.activationDate'),
          selected: true,
          sortable: false,
        },
        {
          value: 'approvalStepPosition',
          name: this.translate.instant('general.datatable.headers.status'),
          selected: true,
          sortable: false,
        },
        ...(!this.selectedForm?.form.isArchived &&
        !this.selectedForm?.form.toBeArchived &&
        !this.selectedForm?.form.approveCompleted
          ? [
              {
                value: 'action',
                name: this.translate.instant('general.datatable.headers.actions'),
                selected: true,
                sortable: false,
                type: PageConfigurationTypes.TABLE,
              },
            ]
          : []),
        {
          value: 'history',
          name: this.translate.instant('general.datatable.headers.history'),
          selected: true,
          sortable: false,
        },
      ];
    }
  }

  private setSortParameter(column: string): string {
    switch (column) {
      case 'issuedDateFormatted':
        return 'issued_date';
      case 'activatedAtFormatted':
        return 'activated_at';
      case 'formIdFormatted':
        return 'form__form_id';
      default:
        return _.snakeCase(column);
    }
  }

  public onFocusOut(): void {
    if (this.formForm.formName.value) {
      this.formForm.formName.value = this.formForm.formName.value.trim();
    }
  }

  public createFormActionPayload(issuerAndReason: IIssuerAndReason): IFormActionPayload {
    const criticalFieldCount = this.helperService.traverseFormIoComponents(this.form.components ?? [], 'criticalField');
    return {
      criticalFieldCount,
      isActive: false,
      activityType: _.get(this.formForm.activityType.value, '0.id', null),
      isSubmitted: false,
      fieldVersionId: this.activeMasterDataId$,
      fieldMeta: this.userDefinedFieldInputModels,
      meta: this.form.components,
      legacyId: this.formForm.legacyId.value,
      versionNumber: this.formForm.versionNumber.value,
      issuedDate: this.formForm.issuedDate.value?.startDate
        ? moment(this.formForm.issuedDate.value.startDate).endOf('day').tz(this.timezone$, true).utc().format()
        : null,
      name: this.formForm.formName.value,
      workflowId: _.get(this.formForm.formApprovalWorkFlow.value, '0.id', null),
      workflowEntriesId: _.get(this.formForm.entryReviewWorkFlow.value, '0.id', null),
      reason: issuerAndReason.reason,
      useCheckIn: this.formForm.useCheckIn.value?.[0].id === 1,
    };
  }

  public setUserDefinedFields(isPreviousVersion: boolean = false): void {
    if (
      !(this.formVersionData$.length && this.targetItem) ||
      this.formActionType === 'add' ||
      this.formActionType === 'add-new-version'
    ) {
      return;
    }

    const formVersionFieldMeta = this.targetItem?.fieldMeta;
    const previousVersionFieldMeta = this.previousVersion?.fieldMeta;

    if (formVersionFieldMeta && !isPreviousVersion) {
      this.modalContent = this.modalContent.map((item) => {
        return {
          ...item,
          inputModel: this.helperService.setUserDefinedFieldsInputModels(
            _.find(formVersionFieldMeta, { fieldId: item.fieldId }),
            this.timezone$,
          ),
        };
      });
    } else if (previousVersionFieldMeta && isPreviousVersion) {
      this.previousVersionMasterData = this.previousVersionMasterData.map((item: IModalContent) => {
        return {
          ...item,
          inputModel: this.helperService.setUserDefinedFieldsInputModels(
            _.find(previousVersionFieldMeta, { fieldId: item.fieldId }),
            this.timezone$,
          ),
        };
      });
    }
  }

  public async decidePDFExportType(parentContainerClass: string): Promise<void> {
    if (this.isPdfEnhancement) {
      await this.downloadDocument(parentContainerClass);
    } else {
      this.downloadForm();
    }
  }

  public downloadForm(): void {
    this.file = _.cloneDeep(this.defaultFileData);
    this.helperService.setFormSelectorsForPdf(
      this.file.formSelectors as IFormPdfProperties[],
      this.formClone,
      EPdfInitialElementId.FORM,
    );
    const selectedItems: HTMLElement[] = Array.from(
      document.querySelectorAll('.printable-formio-form .formio-form > .formio-component'),
    );
    const selectedHiddenItemsLen: number = selectedItems?.filter((item: HTMLElement) =>
      Array.from(item.classList).includes('formio-hidden'),
    )?.length;
    this.file.searchSelectors = ['.is-printable'];

    if (this.formClone.components?.length) {
      this.file.searchSelectors = this.file.searchSelectors.concat(
        Array(this.formClone.components.length - selectedHiddenItemsLen).fill(
          `#${EPdfInitialElementId.FORM} .formio-form > .formio-component`,
        ),
      );
    }

    const archivedText: string = this.selectedForm?.form.isArchived
      ? `(${this.translate.instant('general.userActions.archived')})`
      : '';

    if (this.bannerConfiguration.header) {
      this.bannerConfiguration.header.title = `${this.formForm.formId.value} - ${
        this.formForm.formName.value ?? this.selectedForm?.name ?? ''
      } - ${this.translate.instant('general.version')} ${this.formForm?.versionNumber.value ?? 1} ${archivedText}`;
      this.bannerConfiguration.header.signature = [
        `${this.translate.instant('myTasks.download.reportCreatedBy')} ${this.nameOfUser}`,
        `${this.titleOfUser}, ${this.roleOfUser}, ${this.helperService.formatDateTimeTz(null, true)}`,
      ];
    }

    this.store.dispatch(new AppActions.ShowLoader());
    this.isPrintAllowedSubject.next(true);
  }

  public async downloadDocument(parentContainerClass: string): Promise<void> {
    if (this.bannerConfig.header) {
      this.bannerConfig.header.title = `${this.formForm.formId.value} - ${
        this.selectedForm?.name
      } - ${this.translate.instant('general.version')} ${this.selectedForm?.versionNumber}`;
      this.bannerConfig.header.signature = [
        `${this.translate.instant('myTasks.download.reportCreatedBy')} ${this.nameOfUser}`,
        `${this.titleOfUser}, ${this.roleOfUser}, ${this.helperService.formatDateTimeTz(null, true)}`,
      ];
    }
    this.document = await this.documentGeneratorService.craftPrintableDocument(
      this.documentGeneratorService.getMainComponents('settings-form', parentContainerClass),
      this.formClone,
      parentContainerClass,
      this.bannerConfig,
    );
    this.initializePrintSubject.next(true);
  }

  public showPreviewModal(templateRef: TemplateRef<any>): void {
    this.previewModalContent = _.cloneDeep(this.modalContent);
    this.formClone.components = _.cloneDeep(this.form.components);
    this.formCloneInitial = _.cloneDeep(this.formClone);
    this.formPreviewRef = this.modal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalXl,
    });
  }

  public showMasterDataMismatchModal(): void {
    this.isMasterDataMisMatched = true;
    this.masterDataMismatchModalRef = this.modal.open(this.masterDataMismatchModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  public proceedToEdit(): void {
    if (!this.selectedForm) {
      return;
    }

    this.isMasterDataMisMatched = true;
    this.formActionType = 'edit-modal-open';
    this.masterDataMismatchModalRef?.close();
    this.beforeActionPreparerActionSubject.next(!this.actionTypesWithoutReason.includes(this.formActionType));
  }

  public compareOldNewMasterData(oldMasterData: IFormMasterDataDetail | null): void {
    this.modalContent = ComponentUtilities.generateUserDefinedModalContent(
      _.cloneDeep(this.activeMasterDataData$.fields),
    );

    if (!oldMasterData) {
      return;
    }

    if (!oldMasterData.fields || !this.activeMasterDataData$.fields) {
      return;
    }

    oldMasterData.fields.forEach((oldItem: ILogbookMasterDataFields) => {
      // @ts-ignore
      const commonItem = this.activeMasterDataData$.fields.filter(
        (newItem) => newItem.meta['type'] === oldItem.meta['type'] && newItem.name === oldItem.name,
      )[0];
      if (commonItem) {
        const newItem = this.modalContent.filter(
          (item: IModalContent) => item.name === commonItem.name && item.type === commonItem.meta['type'],
        )[0];
        const targetItemIndex = _.findIndex(this.selectedForm?.fieldMeta, { fieldId: newItem.fieldId });
        const modalContentIndex = _.findIndex(this.modalContent, { fieldId: newItem.fieldId });
        if (targetItemIndex !== -1 && modalContentIndex !== -1) {
          this.modalContent[modalContentIndex] = {
            ...this.modalContent[modalContentIndex],
            inputModel: this.selectedForm?.fieldMeta[targetItemIndex].inputModel,
          };
        }
      }
    });
  }

  public showPassedIssueDateModal(): void {
    this.passedIssueDateModalRef = this.modal.open(this.passedIssueDateModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  public disableDrag(): void {
    document.querySelector('.drag-query-selector-class')?.classList?.remove('overflow-auto');
    document.querySelector('.drag-query-selector-class')?.classList?.add('overflow-hidden');
  }

  public enableDrag(): void {
    document.querySelector('.drag-query-selector-class')?.classList?.remove('overflow-hidden');
    document.querySelector('.drag-query-selector-class')?.classList?.add('overflow-auto');
  }

  public formIOLinkObserverSelector() {
    setTimeout(() => {
      const formDropAreaTarget = document.querySelector('.component-preview');
      if (formDropAreaTarget) {
        this.linkObserver.observe(formDropAreaTarget, {
          subtree: true,
          childList: true,
        });
      }
      document.querySelectorAll('.formio-form').forEach((ele) => {
        if (ele) {
          this.previewLinkObserver.observe(ele, {
            subtree: true,
            childList: true,
          });
        }
      });
    });
    this.getLink();
  }

  public ngAfterViewInit(): void {
    this.defaultSelectionValues['statusDropdown'] = {
      key: 'id',
      values: [-1, -4, -3, 0, 1],
    };
    this.filtersReady = true;
    this.defaultFilterSelectionsSubject.next(this.defaultSelectionValues);
  }

  public ngOnDestroy(): void {
    this.formVersionModalRef?.close();
    this.allVersionsModalRef?.close();
    this.historyModalRef?.close();
    this.modalRef?.close();
    this.defaultModalRef?.close();
    this.passedIssueDateModalRef?.close();
    this.subscriptions.forEach((subs: Subscription) => {
      subs?.unsubscribe();
    });
    this.linkObserver?.disconnect();
    this.previewLinkObserver?.disconnect();
  }

  public prepareAllVersionsModal(id: number): void {
    this.formVersionData$ = [];
    this.resetGetFormVersionsParams();
    this.selectedForm = _.find(this.formTableData$, { id }) as IFormVersionSettings;
    this.isSelectedFormOngoing = false;
    this.selectedFormActivationInProgress = false;
    this.selectedFormOngoingForms = [];
    if (!this.selectedForm) {
      this.allVersionOn = false;
      this.allVersionsModalRef?.close();
      this.store.dispatch(new AppActions.HideLoader());
      return;
    }
    this.allVersionsModalTableHeaderDecider();
    this.getFormVersionsParams = {
      ...this.getFormVersionsParams,
      formId: this.selectedForm?.form?.id ?? null,
    };
    this.loadAllFormVersions();
  }

  public getLink(): void {
    document.querySelectorAll('a[ref="fileLink"]').forEach((ele) => {
      if (!ele) {
        return;
      }
      // @ts-ignore
      if (ele.hasAttribute('data-linkChanged') && !ele.hasAttribute('href')) {
        return;
      }

      ele.setAttribute('data-linkChanged', '1');
      ele.removeAttribute('href');

      const a = ele.cloneNode(true);
      ele.replaceWith(a);

      a?.addEventListener('click', this.downloadFile.bind(this));
    });
  }

  public downloadFile(event: any): boolean {
    event.preventDefault();
    event.stopPropagation();
    this.helperService.showToastMessage(
      false,
      this.translate.instant('general.failed'),
      this.translate.instant('general.fileDownloadError'),
    );
    return false;
  }

  public async readExcel($event: any): Promise<void> {
    this.formActionType = 'excel';
    this.excelFiles = _.first($event.currentTarget.files);
    this.uploaderElementRef.nativeElement.value = null;

    const file: File | undefined = this.excelFiles;

    if (file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
      this.excelErrorHeader = this.translate.instant('excel.dropdownElement.bulkUpload');
      this.excelErrorMessage = this.translate.instant('excel.messages.fileTypeError');
      return;
    }

    this.formsFromExcel = await this.formService.getFormsFromExcel(file, this.activeMasterDataData$);

    if (_.isNil(this.formsFromExcel)) {
      ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
      this.excelErrorHeader = this.translate.instant('excel.dropdownElement.bulkUpload');
      this.excelErrorMessage = this.translate.instant('excel.messages.fileCannotReadError');
      return;
    }

    this.excelTemplateMasterDataId = this.formsFromExcel.fieldVersion;

    if (this.excelTemplateMasterDataId !== this.activeMasterDataId$) {
      ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
      this.excelErrorHeader = this.translate.instant('excel.messages.oldTemplateErrorHeader');
      this.excelErrorMessage = this.translate.instant('excel.messages.oldTemplateError');

      return;
    }

    if (this.formsFromExcel.formData.length === 0) {
      ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
      this.excelErrorHeader = this.translate.instant('excel.dropdownElement.bulkUpload');
      this.excelErrorMessage = this.translate.instant('excel.messages.fileEmptyError');
      this.beforeActionPreparerComponent.closeAllModals();

      return;
    }

    if (this.formsFromExcel.formData.length > this.excelUploadLimit) {
      ComponentUtilities.showExcelError(this.modal, this.excelErrorModalTemplateRef, this.scwModalSm);
      this.excelErrorHeader = this.translate.instant('excel.dropdownElement.bulkUpload');
      this.excelErrorMessage = this.translate.instant('excel.messages.fileUploadLimitExceed', {
        value: this.excelUploadLimit,
      });
      return;
    }

    this.beforeActionPreparerActionSubject.next(true);
  }

  public downloadExcel(): void {
    this.formActionType = 'excel';
    this.excelDownloadPressed = true;
    this.store.dispatch(new FormsActions.GetActiveMasterDataLoading());
  }

  public dropdownClick(key: string): void {
    switch (key) {
      case 'manually':
        this.onIssuerActionClicked('add-modal-open');
        break;
      case 'bulk-upload':
        this.uploaderElementRef.nativeElement.click();
        break;
    }
  }

  private prepareFormExcelData(formExcelData: IFormExcel, issuerAndReason: IIssuerAndReason): IFormCreate[] {
    const payload: IFormCreate[] = [];
    formExcelData.formData.forEach((item: IFormExcelData) => {
      payload.push({
        criticalFieldCount: 0,
        isActive: false,
        isSubmitted: false,
        meta: [],
        activityType: item.activityType,
        fieldVersionId: this.activeMasterDataId$,
        fieldMeta: ComponentUtilities.formatExcelUserDefinedFields(
          item,
          this.activeMasterDataData$,
          this.translate,
          this.timezone$,
          this.excelDateFormat$,
          this.excelTimeFormat$,
        ),
        legacyId: item.legacyId,
        issuedDate: item.issuedDate
          ? moment(item.issuedDate, this.excelDateFormat$, true).tz(this.timezone$, true).endOf('day').utc().format()
          : null,
        name: item.name ? item.name.trim() : null,
        workflowId: item.formTemplateWorkflow,
        workflowEntriesId: item.formEntryWorkflow,
        reason: issuerAndReason.reason,
        useCheckIn: !_.isNull(item.useCheckIn) ? item.useCheckIn === 1 : null,
      });
    });

    return payload;
  }

  public dateInputModelChanged(): void {
    if (this.formForm.issuedDate.value?.startDate) {
      if (
        this.helperService.dateIsAfter(moment().tz(this.timezone$), this.formForm.issuedDate.value?.startDate) &&
        this.formActionType !== 'all-version-show'
      ) {
        this.helperService.setTimezoneToToday(this.formForm, this.timezone$);
      }
    }
  }

  public fetchMasterDataOrCompare(shouldCompareMasterData: boolean): void {
    if (!this.selectedForm || !this.isMasterDataMisMatched) {
      return;
    }

    if (this.selectedForm.fieldVersion) {
      this.store.dispatch(new FormsActions.GetMasterDataLoading(this.activeMasterDataId$, false));
      return;
    }

    if (shouldCompareMasterData) {
      this.modalContent = this.helperService.compareOldNewMasterData(
        null,
        this.activeMasterDataData$,
        this.selectedForm,
      );
      this.masterDataMismatchModalRef?.close();
    }

    this.formVersionModalRef = this.modal.open(this.formModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalXlWithDragSelector,
    });
  }

  private loadAllFormVersions(): void {
    this.store.dispatch(new FormsActions.GetFormVersionsLoading(this.getFormVersionsParams));
  }

  private adjustPreviousVersionFields(previousVersion: IFormVersion): IPreviousFormVersion {
    if (!previousVersion) {
      return {
        formName: null,
        formId: null,
        formApprovalWorkFlow: [],
        entryReviewWorkFlow: [],
        versionNumber: null,
        legacyId: null,
        activityType: [],
        checkInMechanism: [],
        issuedDate: {
          startDate: null,
          endDate: null,
        },
      };
    }

    return {
      formName: previousVersion.name,
      formId: previousVersion.form.formId,
      formApprovalWorkFlow: ComponentUtilities.findOneOptionForSelect(
        this.workflowData$,
        Number(_.get(previousVersion.workflow, 'id', 0)),
        'id',
      ),
      entryReviewWorkFlow: ComponentUtilities.findOneOptionForSelect(
        this.workflowData$,
        Number(_.get(previousVersion.workflowEntries, 'id', 0)),
        'id',
      ),
      versionNumber: previousVersion.versionNumber,
      legacyId: previousVersion.legacyId,
      activityType: ComponentUtilities.findOneOptionForSelect(
        this.formActivityTypeItems$,
        previousVersion.activityType,
        'id',
      ),
      checkInMechanism: ComponentUtilities.findOneOptionForSelect(this.yesNo, previousVersion.useCheckIn ? 1 : 0),
      issuedDate: {
        startDate: previousVersion.issuedDate ? moment(previousVersion.issuedDate).tz(this.timezone$) : null,
        endDate: previousVersion.issuedDate ? moment(previousVersion.issuedDate).tz(this.timezone$) : null,
      },
    };
  }

  private masterDataLoadedShowAllVersions(response: FormsActions.GetMasterDataLoaded): void {
    if (
      this.previousVersion?.fieldVersionId &&
      this.previousVersion?.fieldVersionId !== this.targetItem.fieldVersionId &&
      response.payload.data.id !== this.previousVersion?.fieldVersionId
    ) {
      this.store.dispatch(new FormsActions.GetMasterDataLoading(this.previousVersion?.fieldVersionId as number, true));
    } else {
      this.previousVersionMasterData = [];
    }

    if (
      this.previousVersion?.fieldVersionId !== this.targetItem.fieldVersionId &&
      response.payload.data.id === this.previousVersion?.fieldVersionId
    ) {
      this.previousVersionMasterData = ComponentUtilities.generateUserDefinedModalContent(
        _.cloneDeep(response.payload.data.fields),
      );
      this.setUserDefinedFields(true);
    } else if (this.previousVersion?.fieldVersionId === this.targetItem.fieldVersionId) {
      this.modalContent = this.previousVersionMasterData = ComponentUtilities.generateUserDefinedModalContent(
        _.cloneDeep(response.payload.data.fields),
      );
      this.setUserDefinedFields();
      this.setUserDefinedFields(true);
    } else {
      this.modalContent = ComponentUtilities.generateUserDefinedModalContent(_.cloneDeep(response.payload.data.fields));
      this.setUserDefinedFields();
    }
  }

  public onExcelErrorModalClose($event: any): void {
    $event.dismiss();

    if (!this.excelIsAllFailure && this.isExcelUploadFailure) {
      this.showScopeModal();
    }

    this.isExcelUploadFailure = false;
  }

  public onTableCheckboxChanged(event: MatCheckboxChange): void {
    this.bulkActionService.onTableCheckboxChanged(event, this.selectedFormVersionRecordIds);

    this.setIsSelectAll();
    this.setRecordSelectedValues();
  }

  public selectOrUnselectAll(selectAllOverridingValue?: boolean): void {
    const isSelectAll: boolean = selectAllOverridingValue ?? this.isSelectAll;

    this.bulkActionService.selectOrUnselectAll(
      this.checkBoxElementRef,
      this.versionRecordCheckboxIdPrefix,
      this.formTableData$,
      isSelectAll,
    );
  }

  public setIsSelectAll(): void {
    this.isSelectAll = this.bulkActionService.getIsSelectAll(this.selectedFormVersionRecordIds, this.formTableData$);
  }

  public setRecordSelectedValues(): void {
    this.isDraftRecordSelected = this.bulkActionService.getIsDraftRecordSelected(
      this.selectedFormVersionRecordIds,
      this.formTableData$,
    );

    this.isApprovedOrCompletedRecordSelected = this.bulkActionService.getIsApprovedOrCompletedRecordSelected(
      this.selectedFormVersionRecordIds,
      this.formTableData$,
    );
  }

  public setNoSelectableRecord(): void {
    this.noSelectableRecord = this.bulkActionService.getNoSelectableRecord(this.formTableData$);
  }

  public showBulkActionConfirmationModal(bulkActionType: TBulkActions): void {
    this.bulkActionService.handleBulkActionConfirmationModal(
      'form',
      bulkActionType,
      this.bulkActionConfirmationModalComponent,
      this.selectedFormVersionRecordIds.length === 1,
    );
  }

  public onBulkAction(bulkActionType: TBulkActions): void {
    this.formActionType = bulkActionType;
    this.beforeActionPreparerActionSubject.next(!this.actionTypesWithoutReason.includes(bulkActionType));
  }

  private resetGetFormVersionsParams(): void {
    this.getFormVersionsParams = {
      formId: null,
      page: 1,
      rowsPerPage: 10,
    };
  }

  private checkFormIoComponentValueUniqueness(): void {
    const duplicateValueComponent: IFormIoComponentKeyId[] = this.getDuplicateValues();
    duplicateValueComponent.forEach((component: IFormIoComponentKeyId) => {
      this.addApiKeyNotUniqueErrorMessageToFormIoComponent(component);
    });
    this.isSelectableComponentValueUnique = duplicateValueComponent.length === 0;
  }

  private getDuplicateValues(): IFormIoComponentKeyId[] {
    const foundKeys: IFormIoComponentKeyId[] = this.helperService.getFormIoComponentsKeys({
      components: this.form.components ?? [],
      isTableRows: false,
    });
    const duplicateComponentValues: IFormIoComponentKeyId[] = [];

    foundKeys?.forEach((component: IFormIoComponentKeyId) => {
      const seenValues = new Set<string>();

      if (component.type === 'selectboxes' || component.type === 'radio') {
        component.values?.forEach((field: { value: string }) => {
          if (seenValues.has(field.value)) {
            duplicateComponentValues.push({
              id: component.id,
              key: component.key,
              type: component.type as TFormIoComponentKey,
              label: component.label,
            });
          } else {
            seenValues.add(field.value);
          }
        });
      }
    });

    return duplicateComponentValues;
  }

  private checkFormIoComponentApiKeyUniqueness(): boolean {
    const foundKeys: IFormIoComponentKeyId[] = this.helperService.getFormIoComponentsKeys({
      components: this.form.components ?? [],
      isTableRows: false,
    });
    const keyCounts: _.Dictionary<number> = _.countBy(foundKeys, 'key');
    const duplicateKeyComponents: IFormIoComponentKeyId[] = _.filter(
      foundKeys,
      (component: IFormIoComponentKeyId): boolean => keyCounts[component.key] > 1,
    );

    duplicateKeyComponents.forEach((component: IFormIoComponentKeyId): void => {
      this.addApiKeyNotUniqueErrorMessageToFormIoComponent(component, 'apiKey');
    });

    return duplicateKeyComponents.length === 0;
  }

  private addApiKeyNotUniqueErrorMessageToFormIoComponent(component: IFormIoComponentKeyId, key?: string): void {
    const formIoComponentElement: Element | null | undefined = document.getElementById(component.id);

    if (!formIoComponentElement) {
      return;
    }

    const componentMessageContainer: Element | null | undefined = _.last(
      formIoComponentElement.querySelectorAll('[ref=messageContainer]'),
    );

    if (!componentMessageContainer) {
      return;
    }

    const messageContainerElement: Element = document.createElement('div');
    const messageContainerId: string = `e-${component.id}-${component.key}`;

    const isLayoutComponent: boolean = this.helperService.isFormIoLayoutComponent(component);
    const isNonFieldAreaComponent: boolean = this.helperService.isFormIoNonFieldAreaComponent(component);

    if (isLayoutComponent) {
      this.sanitizeFaultyFormIoError(messageContainerId);
    }

    messageContainerElement.classList.add('form-text');
    messageContainerElement.classList.add('error');
    messageContainerElement.id = messageContainerId;

    const translationKey: string =
      key === 'apiKey' ? 'settings.forms.apiKeyNotUnique' : 'settings.forms.valueNotUnique';

    messageContainerElement.textContent = this.translate.instant(translationKey, {
      key: key === 'apiKey' ? component.key : undefined,
    });
    componentMessageContainer.replaceChildren(messageContainerElement);

    formIoComponentElement.parentElement?.classList.add('has-error');
    formIoComponentElement.parentElement?.classList.add('has-message');

    if (isNonFieldAreaComponent) {
      this.addErrorBorderToFormIoComponent(formIoComponentElement as HTMLElement);
      return;
    }

    const componentFieldElement: Element | null | undefined = formIoComponentElement.querySelector(
      'input:not([type="hidden"]), textarea, .selection.dropdown',
    );

    if (!_.isNil(componentFieldElement)) {
      componentFieldElement.classList.add('is-invalid');
    }
  }

  private sanitizeFaultyFormIoError(componentId: string): void {
    const faultyFormIoErrorComponent: Element | null | undefined = document.getElementById(componentId);

    faultyFormIoErrorComponent?.remove();
  }

  private addErrorBorderToFormIoComponent(componentElement: HTMLElement): void {
    componentElement?.style.setProperty('border', 'dotted 1.5px #dc3545', 'important');
  }
}
