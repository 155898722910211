import { IStateRuleAndAutomationState } from './state-rule-and-automation.model';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as Actions from './state-rule-and-automation.actions';

export const initialState: IStateRuleAndAutomationState = {
  relevantDataLoading: false,
  relevantDataLoaded: false,
  relevantData: null,
};

export const stateRuleAndAutomationReducer: ActionReducer<IStateRuleAndAutomationState> = createReducer(
  initialState,
  on(Actions.GetRelevantData, (state: IStateRuleAndAutomationState) => ({
    ...state,
    relevantDataLoading: true,
    relevantDataLoaded: false,
  })),
  on(Actions.GetRelevantDataCompleted, (state: IStateRuleAndAutomationState, { payload }) => ({
    ...state,
    relevantDataLoading: false,
    relevantDataLoaded: true,
    relevantData: payload,
  })),
  on(Actions.FetchError, (state: IStateRuleAndAutomationState) => ({
    ...state,
    relevantDataLoading: false,
    relevantDataLoaded: false,
  })),
);
