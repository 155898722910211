import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../logbook.reducer';
import { RuleService } from './rule.service';
import * as RuleActions from './rule.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ERuleType, IAddRule, IBulkEditRule, IRule } from './rule.model';
import { catchError } from 'rxjs/operators';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import * as AppActions from '../../../app/actions';
import { ERuleErrorTypes, IRuleTableQuery } from '../../../../view/settings/state-rule-and-automation/rules/rule.model';
import * as _ from 'lodash';

@Injectable()
export class RuleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: RuleService,
  ) {}

  getRuleData = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.RULE_DATA_LOADING),
      switchMap((payload: { tableQuery: IRuleTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
          ...(payload.tableQuery.logbookIds && { logbookIds: payload.tableQuery.logbookIds }),
          ...(payload.tableQuery.ruleTypes && { ruleTypes: payload.tableQuery.ruleTypes }),
        });

        return this.service.getRules(httpParams).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IRule>) => {
            const orderedResponse: IGetManyResponse<IRule> = {
              ...response,
              data: response.data.map((row: IRule) => {
                if (row.ruleType.name === ERuleType.MULTIPLE_STATES_RULE) {
                  Object.assign(row.details, { states: _.orderBy(row.details.states, 'ordering') });
                }

                return row;
              }),
            };

            return RuleActions.RulesDataLoaded({ payload: orderedResponse });
          }),
          catchError((error) => of(RuleActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  addRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.ADD_RULE),
      switchMap((payload: { rule: IAddRule; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addRule(payload.rule, payload.issuer).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IBaseOneResponse<IRule>) => RuleActions.AddRuleCompleted({ payload: response.data })),
          catchError((error) =>
            of(
              RuleActions.FetchError({
                payload: { name: _.get(error, 'error.data.name'), type: _.get(error, 'error.data.error') },
              }),
            ),
          ),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  editRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.EDIT_RULE),
      switchMap((payload: { rule: IAddRule; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editRule(payload.rule, payload.id, payload.issuer).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IBaseOneResponse<IRule>) => RuleActions.EditRuleCompleted({ payload: response.data })),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === ERuleErrorTypes.COLLISION) {
              return of(
                RuleActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  deleteRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.DELETE_RULE),
      switchMap((payload: { ruleIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteRule(payload.ruleIds, payload.issuerAndReason).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IBulkResponseRecord<IRule>>) =>
            RuleActions.DeleteRuleCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => {
        return of(RuleActions.FetchError({ payload: error }));
      }),
    ),
  );

  bulkEditRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.BULK_EDIT_RULE),
      switchMap((payload: { rules: IBulkEditRule[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditRule(payload.rules, payload.issuerAndReason).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IBulkResponseRecord<IRule>>) =>
            RuleActions.BulkEditRuleCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === ERuleErrorTypes.COLLISION) {
              return of(
                RuleActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );
}
