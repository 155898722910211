import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './logbooks.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../logbook.reducer';
import * as AppActions from '../app/actions';
import * as _ from 'lodash';
import { LogbooksService } from './logbooks.service';
import {
  IGetLogbookHistoryParams,
  IGetLogbookVersionsParams,
  ILogbook,
  ILogbookExcelWithError,
  ILogbookSettings,
  ILogbookStates,
  IStateStates,
} from './logbooks.model';
import {
  IBaseCrudResponse,
  IBaseOneResponse,
  IBulkResponseData,
  IBulkResponseRecord,
  IGetManyResponse,
  IGetOneResponse,
} from '../../shared/model/interface/crud-response-interface.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';
import { LogbookMasterDataService } from '../settings/logbook-master-data/logbook-master-data.service';
import { ILogbookMasterData } from '../settings/logbook-master-data/logbook-master-data.model';
import { WorkflowsService } from '../settings/workflows/workflows.service';
import { IWorkflowStepsData } from '../../view/settings/workflows/workflows.model';
import { ILogbookVersion, IPassedIssueDateLogbooks } from '../logbook-versions/logbook-versions.model';
import { LogbookVersionsService } from '../logbook-versions/logbook-versions.service';
import { ILogbookHistory } from '../../view/settings/logbook/logbook.model';
import { IForm } from '../forms/forms.model';
import { IWorkflow } from '../settings/workflows/workflows.model';
import { emptyAction } from '../../../constants';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { ExcelHelperService } from '../../shared/service/excel/excel-helper.service';
import { UpdateUserLogbookScopeIds } from '../user/actions';
import { IBulkActionSingleResponse } from '../../shared/service/bulk-action/bulk-action.model';

@Injectable()
export class LogbooksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly service: LogbooksService,
    private readonly logbookVersionService: LogbookVersionsService,
    private readonly workFlowService: WorkflowsService,
    private readonly logbookMasterDataService: LogbookMasterDataService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  addLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LOGBOOK),
      switchMap((payload: ObjectActions.AddLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addLogbook(payload.logbook, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<ILogbook>) => {
            return of(
              new ObjectActions.AddLogbookCompleted(response),
              new UpdateUserLogbookScopeIds([response.data.id]),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LOGBOOK),
      switchMap((payload: ObjectActions.EditLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service
          .editLogbook(payload.logbook, payload.logbookId, payload.logbookVersionId, payload.issuer)
          .pipe(
            switchMap((response: IBaseOneResponse<any>) => {
              return of(new ObjectActions.EditLogbookCompleted(response));
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addLogbookVersion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_LOGBOOK_VERSION),
      switchMap((payload: ObjectActions.AddLogbookVersion) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addLogbookVersion(payload.logbookId, payload.logbookVersion, payload.issuer).pipe(
          switchMap((response: IBaseOneResponse<ILogbookVersion>) => {
            return of(new ObjectActions.AddLogbookVersionCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookChildren = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_CHILDREN_DATA_LOADING),
      switchMap((payload: ObjectActions.GetLogbookChildrenDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          ...(payload?.searchString && { search: payload.searchString }),
          ...(Array.isArray(payload.statusIds) && { statuses: JSON.stringify(payload.statusIds) }),
        });

        return this.service.getChildrenLogbooks(payload.parentId, httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(new ObjectActions.GetLogbookChildrenDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOGBOOKS_DATA_LOADING),
      switchMap((payload: ObjectActions.LogbooksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery?.page ?? 1,
          rowsPerPage: payload.tableQuery?.rowsPerPage ?? 10,
          limit: 0,
          sort: payload.tableQuery?.sort ?? { type: '-', column: 'id' },
          ...(payload.tableQuery?.search && { search: payload.tableQuery.search }),
          ...(!_.isNil(payload.tableQuery?.isActive) && { isActive: Boolean(payload.tableQuery?.isActive) }),
        });

        if (Array.isArray(payload.statuses)) {
          if (payload.statuses.includes(-2)) {
            httpParams = httpParams.append('include_archived', 1);
          }
          httpParams = httpParams.append('statuses', JSON.stringify(payload.statuses));
        } else if (payload.includeArchived) {
          httpParams = httpParams.append('include_archived', 1);
        }

        return this.service.getLogbooks(httpParams, payload.issuer).pipe(
          switchMap((response: IGetManyResponse<ILogbook>) => {
            return of(
              new ObjectActions.LogbooksDataLoaded(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getAddEditLogbookData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SPECIFIC_LOGBOOK_DATA_LOADING),
      switchMap((payload: ObjectActions.GetSpecificLogbookData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service
          .getLogBookData(
            payload.logbookId,
            payload.logbookVersionId,
            payload.previousVersionId,
            payload.issuer,
            payload.isIncludeDisabled,
            payload.isOnlyActiveForms,
          )
          .pipe(
            switchMap((response: ILogbookSettings) => {
              return of(
                new ObjectActions.GetSpecificLogbookDataLoaded(response, payload.dispatchLocation),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookVersions = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_VERSION),
      switchMap((payload: ObjectActions.GetAllLogbookVersions) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: IGetLogbookVersionsParams = payload.params;
        const httpParams: HttpParams = new HttpParams()
          .append('sort_version', 1)
          .append('ongoing_forms', 1)
          .append('page', params.page)
          .append('limit', params.rowsPerPage);

        return this.service.getLogbookVersions(params.logbookId, httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookVersion>) => {
            return of(
              new ObjectActions.GetAllLogbookVersionsCompleted(response, payload.dispatchLocation),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_FORMS),
      switchMap((payload: ObjectActions.GetLogbookForms) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLogbookForms(payload.formIds).pipe(
          switchMap((response: IGetManyResponse<any>) => {
            return of(new ObjectActions.GetLogbookFormsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  cloneLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CLONE_LOGBOOK),
      switchMap((payload: ObjectActions.CloneLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.cloneLogbook(payload.clonedLogbook, payload.logbookId, payload.issuer).pipe(
          switchMap((response: IGetManyResponse<any>) => {
            return of(new ObjectActions.CloneLogbookCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_MASTER_DATA),
      switchMap((payload: ObjectActions.GetLogbooksMasterData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.logbookMasterDataService.getLogbookMasterDetailData(payload.masterDataId).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.GetLogbooksMasterDataCompleted(
                {
                  success: response[0].success && response[1].success,
                  data: { ...response[0].data, fields: response[1].data ?? [] },
                },
                payload.dispatchLocation,
              ),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActiveLogbookMasterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ACTIVE_LOGBOOK_MASTER_DATA),
      switchMap((payload: ObjectActions.GetActiveLogbooksMasterData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().set('is_active', true);
        return this.logbookMasterDataService.getLogbookMasterData(httpParams).pipe(
          switchMap((response: IGetManyResponse<ILogbookMasterData>) => {
            return of(
              new ObjectActions.GetLogbooksMasterData(response.data[0].id, 'all'),
              new ObjectActions.GetActiveLogbooksMasterDataCompleted(response),
              payload.dispatchHideLoader ? new AppActions.HideLoader() : new AppActions.EmptyAction(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkFlowStepData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOW_STEP_DATA_LOADING),
      switchMap((payload: ObjectActions.WorkflowStepsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workFlowService.getWorkflowsSteps(payload.id).pipe(
          switchMap((response: IGetManyResponse<IWorkflowStepsData>) => {
            return of(new ObjectActions.WorkflowStepsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  sendLogbookToArchive = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SEND_LOGBOOK_TO_ARCHIVE),
      switchMap((payload: ObjectActions.SendLogbookToArchive) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.sendLogbookToArchive(payload.id, payload.issuerAndReason).pipe(
          switchMap((response: IGetManyResponse<any>) => {
            return of(new ObjectActions.SendLogbookToArchiveCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  archiveLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ARCHIVE_LOGBOOK),
      switchMap((payload: ObjectActions.ArchiveLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.archiveLogbook(payload.id, payload.issuerAndReason).pipe(
          switchMap((response: IBaseOneResponse<ILogbook>) => {
            return of(new ObjectActions.ArchiveLogbookCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteLogbookVersion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_LOGBOOK_VERSION),
      switchMap((payload: ObjectActions.DeleteLogbookVersion) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service
          .deleteLogbookVersion(payload.logbookId, payload.logbookVersionId, payload.issuerAndReason)
          .pipe(
            switchMap((response: IBaseOneResponse<ILogbookVersion>) => {
              return of(new ObjectActions.DeleteLogbookVersionCompleted(response));
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes, errorRes.error?.data?.error),
                new AppActions.HideLoader(),
              );
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  submitLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SUBMIT_LOGBOOK),
      switchMap((payload: ObjectActions.SubmitLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.submitLogbook(payload.logbookVersionId, payload.logbookId, payload.issuer).pipe(
          switchMap((response: IGetManyResponse<any>) => {
            return of(new ObjectActions.SubmitLogbookCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  activateLogbook = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVATE_LOGBOOK),
      switchMap((payload: ObjectActions.ActivateLogbook) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.activateLogbook(payload.logbookVersionId, payload.logbookId, payload.issuer).pipe(
          switchMap((response: IGetManyResponse<any>) => {
            return of(new ObjectActions.ActivateLogbookCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_HISTORY),
      switchMap((payload: ObjectActions.GetLogbookHistory) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: IGetLogbookHistoryParams = payload.params;
        let httpParams: HttpParams = new HttpParams();

        if (!_.isNil(params.logbookId)) {
          httpParams = httpParams.append('logbookId', params.logbookId);
        } else if (!_.isNil(params.versionIds)) {
          httpParams = httpParams.append('versionIds', params.versionIds.join(','));
        }

        return this.service.getLogbookHistory(httpParams, payload.logbookID, payload.getTemplateHistory).pipe(
          switchMap((response: IGetManyResponse<ILogbookHistory>) => {
            return of(new ObjectActions.GetLogbookHistoryCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editOrderBy = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_LOGBOOK_VERSION_ORDER_BY),
      switchMap((payload: ObjectActions.EditLogbookVersionOrderBy) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOrderBy(payload.logbookVersionIds).pipe(
          switchMap((response: IBaseOneResponse<ILogbookVersion>) => {
            return of(new ObjectActions.EditLogbookVersionOrderByCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getAddEditModalData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ADD_EDIT_MODAL_DATA),
      switchMap((payload: ObjectActions.GetAddEditModalData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.populateAddEditModalData(payload.logbookId, payload.logbookVersionId, payload.issuer).pipe(
          switchMap((response: any) => {
            return of(
              new ObjectActions.GetAddEditModalDataCompleted(response, payload.isEdit),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  changeLogbookParent = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHANGE_LOGBOOK_PARENT),
      switchMap((payload: ObjectActions.ChangeLogbookParent) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service
          .changeLogbookParent(
            payload.logbookId,
            payload.logbookVersionId,
            payload.parentLogbookId,
            payload.issuerAndReason,
          )
          .pipe(
            switchMap((response: IBaseOneResponse<ILogbookVersion>) => {
              return of(new ObjectActions.ChangeLogbookParentCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  checkPassedIssueDate = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_PASSED_ISSUE_DATE),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams();

        return this.logbookVersionService.getLogbookVersionsPassedIssue(httpParams).pipe(
          switchMap((response: IGetManyResponse<IPassedIssueDateLogbooks>) => {
            return of(new ObjectActions.CheckPassedIssueDateCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getAllForms = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ALL_FORMS),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getAllForms().pipe(
          switchMap((response: IGetManyResponse<IForm>) => {
            return of(new ObjectActions.GetAllFormsCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkflowData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORKFLOW_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('limit', 1000).append('just_workflow_data', true);

        return this.workFlowService.getWorkflows(httpParams).pipe(
          switchMap((response: IGetManyResponse<IWorkflow>) => {
            return of(new ObjectActions.GetWorkflowDataCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.DownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(false, objectData.activeMasterDataData, objectData.workflows);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EXCEL),
      switchMap((objectData: ObjectActions.UploadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.logbooks, objectData.issuerAndReason, objectData.scopeId).pipe(
          switchMap((response: IBulkResponseData) => {
            const errorsWithKeysMapped: ILogbookExcelWithError[] = [];

            (response.errors as unknown as ILogbookExcelWithError[])?.forEach((user: ILogbookExcelWithError) =>
              errorsWithKeysMapped.push(
                _.mapKeys(user, (v, k: string) => _.camelCase(k)) as unknown as ILogbookExcelWithError,
              ),
            );

            this.errorMessageService.getTranslatedErrorMessage(errorsWithKeysMapped ?? []);

            const mergedArray: ILogbookExcelWithError[] =
              this.excelHelperService.mergeBulkResponseWithRequestData<ILogbookExcelWithError>(
                { data: errorsWithKeysMapped as IBaseCrudResponse[], success: response.success },
                errorsWithKeysMapped,
              );

            return of(
              new ObjectActions.UploadExcelCompleted(
                mergedArray,
                response.success,
                objectData.issuerAndReason,
                objectData.dispatchLocation,
              ),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadErrorExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_ERROR_EXCEL),
      switchMap((objectData: ObjectActions.DownloadErrorExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(
          objectData.withData,
          objectData.activeMasterDataData,
          objectData.workflow,
          true,
          objectData.payload,
          objectData.issuerAndReason,
        );

        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkSubmitLogbooks = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_SUBMIT_LOGBOOKS),
      switchMap((payload: ObjectActions.BulkSubmitLogbooks) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkSubmitLogbooks(payload.logbookVersionIds, payload.issuer).pipe(
          switchMap((response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) => {
            return of(new ObjectActions.BulkSubmitLogbooksCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkActivateLogbooks = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_ACTIVATE_LOGBOOKS),
      switchMap((payload: ObjectActions.BulkActivateLogbooks) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkActivateLogbooks(payload.logbookVersionIds, payload.issuer).pipe(
          switchMap((response: IBulkResponseRecord<IGetOneResponse<IBulkActionSingleResponse>>) => {
            return of(new ObjectActions.BulkActivateLogbooksCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLogbookStatesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LOGBOOK_STATES_DATA_LOADING),
      switchMap((payload: ObjectActions.GetLogbookStatesDataLoading) => {
        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          sort: { type: '', column: 'name' },
        });

        return this.service.getLogbookStateData(payload.logbookId, httpParams).pipe(
          switchMap((response: IGetOneResponse<ILogbookStates>) => {
            return of(new ObjectActions.GetLogbookStatesDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getStateListData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_STATES_LIST_DATA_LOADING),
      switchMap((payload: ObjectActions.GetStatesListDataLoading) => {
        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          sort: { type: '', column: 'name' },
          limit: 5000,
          offset: 0,
        });

        return this.service.getStateListData(httpParams).pipe(
          switchMap((response: IGetManyResponse<IStateStates>) => {
            return of(new ObjectActions.GetStatesListDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  postLogbookStateData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_LOGBOOK_STATES),
      switchMap((payload: ObjectActions.UpdateLogbookStates) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({});

        return this.service
          .postLogbookStateData(payload.logbookId, payload.logbookStates, payload.issuerAndReason)
          .pipe(
            switchMap((response: IGetManyResponse<ILogbookStates>) => {
              return of(new ObjectActions.UpdateLogbookStatesCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  updateLogbookCurrentStates = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_LOGBOOK_CURRENT_STATES),
      switchMap((payload: ObjectActions.UpdateLogbookCurrentStates) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.updateLogbookCurrentStates(payload.logbookId, payload.states, payload.issuerAndReason).pipe(
          switchMap((response: IGetManyResponse<ILogbookStates>) => {
            return of(new ObjectActions.UpdateLogbookCurrentStatesCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
