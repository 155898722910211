import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../logbook.reducer';
import { AutomationService } from './automation.service';
import * as AutomationActions from './automation.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IAddAutomation, IAutomation, IBulkEditAutomation } from './automation.model';
import { catchError } from 'rxjs/operators';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import * as AppActions from '../../../app/actions';
import {
  EAutomationErrorTypes,
  IAutomationTableQuery,
} from '../../../../view/settings/state-rule-and-automation/automations/automations.model';
import * as _ from 'lodash';
import * as RuleActions from '../rules/rule.actions';

@Injectable()
export class AutomationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: AutomationService,
  ) {}

  getAutomationData = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.AUTOMATION_DATA_LOADING),
      switchMap((payload: { tableQuery: IAutomationTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.logbookIds && { logbookIds: payload.tableQuery.logbookIds }),
          ...(payload.tableQuery.automationTypes && { automationTypes: payload.tableQuery.automationTypes }),
          ...(payload.tableQuery.isActive?.length && { isActive: !!payload.tableQuery.isActive[0] }),
        });

        return this.service.getAutomations(httpParams).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IAutomation>) =>
            AutomationActions.AutomationsDataLoaded({ payload: response }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  addAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.ADD_AUTOMATION),
      switchMap((payload: { automation: IAddAutomation; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addAutomation(payload.automation, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.AddAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  editAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.EDIT_AUTOMATION),
      switchMap((payload: { automation: IAddAutomation; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editAutomation(payload.automation, payload.id, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.EditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  deleteAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.DELETE_AUTOMATION),
      switchMap((payload: { automationIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteAutomation(payload.automationIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.DeleteAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  bulkEditAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.BULK_EDIT_AUTOMATION),
      switchMap((payload: { automations: IBulkEditAutomation[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditAutomation(payload.automations, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.BulkEditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );
}
